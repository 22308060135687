import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getStudentProfile, logout } from "../../api/services";
import { icons } from "../../assets";
import logo from "../../assets/images/logo.png";
import UploadForm from "../Upload";

const style = {
  p: 4,
};

function Header() {
  const navigate = useNavigate();
  const [open1, setOpen1] = React.useState(false);
  const accessToken = localStorage.getItem("access_token");

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate } = useMutation(logout, {
    onSuccess: () => {
      toast.success("Logged out successfully");
      setAnchorEl(null);
      localStorage.clear();
      navigate("/");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: studentData } = useQuery("studentProfile", getStudentProfile);

  const uploadFiles = () => {
    if (
      accessToken &&
      (!studentData?.data?.sscKey ||
        !studentData?.data?.aadharKey ||
        !studentData?.data?.imageKey ||
        !studentData?.data?.signatureKey)
    ) {
      setOpen1(true);
    }
  };

  useEffect(() => {
    if (studentData) uploadFiles();
  }, [studentData]);

  return (
    <Box
      sx={{
        height: "60px",
        boxShadow: "0px 3px 3px #11234B29",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{ width: "max-content", cursor: "pointer", pl: 2 }}
        onClick={() => navigate("/")}
      >
        <img
          src={logo}
          alt="logo"
          height={"65px"}
          style={{ objectFit: "cover", padding: "10px" }}
        />
      </Box>
      <Box sx={{ pr: 2 }}>
        <IconButton
          disableRipple
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleClick}
          color="inherit"
          sx={{
            fontSize: "2.5rem",
            gap: 1,
            ":hover": { backgroundColor: "transparent" },
          }}
        >
          {/* <AccountCircle sx={{ fontSize: "inherit" }} /> */}
          <Avatar
            alt={studentData?.data?.name}
            src={studentData?.data?.imageUrl}
          />
          {studentData?.data?.name && (
            <Button
              disableRipple
              variant="text"
              endIcon={<KeyboardArrowDownIcon />}
              sx={{ px: 0, ":hover": { backgroundColor: "transparent" } }}
            >
              {studentData?.data?.name}
            </Button>
          )}
        </IconButton>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-dashboard");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.DashboardBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Dashboard
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-trade-timetable");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.TradeTimetableBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Trade Timetable
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-learning");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.LearningBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Learning
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/online-test");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.OnlineTestBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Test
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/doubts");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.DoubtsBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Doubts
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/personal-notes");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.personalNotes}
              style={{ height: "17px", width: "17px" }}
            />
            Personal Notes
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/e-books");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.EBooksBlack}
              style={{ height: "17px", width: "17px" }}
            />
            E-Books
          </MenuItem>

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/my-discussion-forum");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.DiscussionForumBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Discussion Forum
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/live-sessions");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.LiveSessionsBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Live Sessions
          </MenuItem>

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/noticeboard");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.NoticeBoardBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Notice Board
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/exam-timetable");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.ExamTimetableBlack}
              style={{ height: "17px", width: "17px" }}
            />
            Exam Timetable
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              navigate("/profile");
            }}
            sx={{ gap: "7px" }}
          >
            <img
              src={icons.AccountDetailsBlack}
              style={{ height: "17px", width: "17px" }}
            />
            User Profile
          </MenuItem>
          <MenuItem onClick={() => mutate()} sx={{ color: "#F4347F" }}>
            Sign out
          </MenuItem>
        </Menu>
      </Box>

      <Dialog
        // sx={{ width: "70%" }}
        open={open1}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <UploadForm />
        </Box>
      </Dialog>
    </Box>
  );
}

export default Header;
