import { Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  beginQuiz,
  getQuizData,
  getStudentProfile,
} from "../../api/services/quiz";
import { logo } from "../../assets";

var elem: any = document.documentElement;

export function disableRightClick(event) {
  event.preventDefault();
}

export function openFullscreen() {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
  document.addEventListener("keydown", handleKeyDown);
}

export function closeFullscreen() {
  if (elem.exitFullscreen) {
    elem.exitFullscreen();
  } else if (elem.webkitExitFullscreen) {
    elem.webkitExitFullscreen();
  } else if (elem.msExitFullscreen) {
    elem.msExitFullscreen();
  }
  document?.exitFullscreen();
}

export const handleKeyDown = (e: KeyboardEvent) => {
  if (
    e.ctrlKey ||
    e.key === "Escape" ||
    (e.key.startsWith("F") && e.key.length === 2 && !isNaN(Number(e.key[1]))) ||
    (e.key.startsWith("F") &&
      e.key.length === 3 &&
      !isNaN(Number(e.key.slice(1))))
  ) {
    e.preventDefault();
  }
};

export const Instructions = ({ setValue }: any) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [checked, setChecked] = useState(false);
  const { bundleId, courseId } = useParams();
  const { data: examData, isLoading: examLoading } = useQuery(
    ["getQuizData", id],
    getQuizData
  );
  const searchParams = new URLSearchParams(window.location.search);

  const { data: studentData } = useQuery("student-profile", getStudentProfile);

  const { mutate, isLoading } = useMutation(beginQuiz, {
    onSuccess: (data: any) => {
      navigate(
        `/learnings/bundle/${bundleId}/course/${courseId}/begin-test/quiz/${id}/response/${
          data?.data?.QuizResponse?._id
        }/?${searchParams.toString()}`
      );
    },
    onError: (e: any) => {
      toast.error(e.response.data.message || "Something went wrong");
      setTimeout(() => {
        navigate(
          `/learnings/bundle/${bundleId}/course/${courseId}/?${searchParams.toString()}`
        );
      }, 3000);
    },
  });

  const handleSubmit = () => {
    try {
      const postBody = {
        userId: studentData?._id,
        quizId: id,
      };
      mutate(postBody);
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  return (
    <Box
      sx={{
        height: `100vh`,
        width: "100%",
        py: 5,
        alignContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          alignItems: "center",
          boxShadow: "0px 6px 16px #21468329",
          borderRadius: "15px",
          maxWidth: "1440px",
          p: 3,
          gap: 2,
          margin: "auto",
        }}
      >
        <img src={logo} width={220} height={80} />
        <Typography fontSize={"18px"} fontWeight={600}>
          Please read the instructions carefully
        </Typography>
        <Box
          sx={{
            my: 3,
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
            width: "80%",
            gap: 2,
          }}
        >
          <Typography
            sx={{ textDecoration: "underline", fontFamily: "figtree_semibold" }}
            variant="h5"
          >
            General Instructions
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            1. The exam has a strict time limit, and it will automatically end
            once the time is up.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            2. Ensure a stable internet connection. Use a laptop or desktop with
            an updated browser (Google Chrome or Mozilla Firefox).
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            3. You are not allowed to seek help from others or use any external
            resources during the exam.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            4. Copying and pasting content is strictly prohibited. All responses
            must be typed in your own words.
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontSize: "14px", fontFamily: "figtree_semibold" }}
          >
            5. You must complete the exam within the given time. If you fail to
            submit within the time, your answers will be automatically submitted
            when the exam ends.
          </Typography>
        </Box>

        <Box
          sx={{
            pb: 2,
            bottom: 10,
            width: "100%",
            textAlign: "left",
          }}
        >
          <Divider sx={{ opacity: 0.4 }} />
          <Box sx={{ px: 16 }}>
            <Typography sx={{ pt: 2, fontSize: "16px", fontWeight: 600 }}>
              Declaration
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                py: "3px",
                gap: 1,
              }}
            >
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ p: 0, m: 0 }}
              />

              <Typography
                sx={{
                  fontSize: "14px",
                  fontFamily: "figtree_semibold",
                  "& *": {
                    margin: 0,
                    padding: 0,
                  },
                }}
              >
                I hereby declare that I will take the exam with integrity,
                without any unfair assistance, and will comply with all the
                rules and regulations outlined by the exam authority.
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 3,
            }}
          >
            <Button
              disabled={!checked || isLoading}
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              sx={{ fontSize: "14px", px: 2 }}
            >
              I am ready to begin
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
