import { Box, Button, LinearProgress, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../../../../assets";
import { capitalize } from "../../../../../utils";
import { StyledAbsoleteButton } from "../../../../NewLoginPage/Pages/Curriculum/Tabs/EBooks";

export const CourseCard = ({ data, bundle, tradeName }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleCourse = () => {
    if (data?.progress > 0 && data?.continueCourse) {
      searchParams.set("topicId", data?.continueCourse?.topicId);
      searchParams.set("sectionId", data?.continueCourse?.sectionId);
      searchParams.set("chapterId", data?.continueCourse?.chapterId);
      navigate(
        `/learnings/bundle/${bundle}/course/${
          data?._id
        }?${searchParams.toString()}`
      );
    } else {
      navigate(`/learnings/bundle/${bundle}/course/${data?._id}`);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "170px",
        padding: "10px",
        px: 2,
      }}
    >
      <>
        <Box sx={{ display: "flex", gap: "30px" }}>
          <Box
            sx={{
              position: "relative",
              width: {
                xs: "130px",
                sm: "220px",
                lg: "220px",
              },
              borderRadius: "10px",
              backgroundImage: `URL(${data?.imageUrl})`,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              height: "140px",
              border: "1px solid #cecece75",
            }}
          >
            <StyledAbsoleteButton
              sx={{
                width: "110px",
                gap: 1,
              }}
            >
              <img src={icons.book} alt="course" />
              <Typography color={"white"} fontSize={"14px"}>
                Course
              </Typography>
            </StyledAbsoleteButton>
          </Box>
          <Stack
            alignItems={"flex-start"}
            justifyContent={"center"}
            gap={"5px"}
          >
            <Box
              sx={{
                backgroundColor: "#2F468C",
                padding: "5px 15px",
                borderRadius: "20px",
              }}
            >
              <Typography
                color={"white"}
                sx={{
                  fontSize: "14px",
                }}
              >
                {capitalize(tradeName)}
              </Typography>
            </Box>
            <Typography sx={{ fontSize: "18px" }} variant="h5">
              {capitalize(data?.title)}
            </Typography>
            <Box sx={{ width: "20vw", pt: 2 }}>
              <Typography
                sx={{
                  textAlign: "right",
                  p: 0,
                  fontSize: "12px",
                  color: "#F4347F",
                  fontWeight: 800,
                }}
                variant="subtitle2"
              >
                {`${Math.round(data?.progress * 100) / 100}% Completed`}
              </Typography>
              <LinearProgress variant="determinate" value={data?.progress} />
            </Box>
          </Stack>
        </Box>
      </>
      <Button
        variant="contained"
        sx={{
          padding: "5px",
          width: "150px",
          fontSize: "14px",
        }}
        onClick={handleCourse}
      >
        {data?.progress > 0 && data?.continueCourse
          ? "Continue Course"
          : "Start Course"}
      </Button>
    </Box>
  );
};
