import { Box, Link } from "@mui/material";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getDiscussionForum } from "../../../../api/services/discussionForum";
import FormOutlinedInput from "../../../../components/FormFields/FormOutlinedInput";
import TableComponent from "../../../../components/TableComponent/TableComponent";
import Continue from "../../components/Continue";

function MyDiscussionForum() {
  const [limit, setlimit] = useState(10);
  const [offset, setoffset] = useState(0);
  const navigate = useNavigate();
  const { control, watch } = useForm();

  const { data } = useQuery(
    [
      "discussion-forum",
      {
        offset: offset,
        limit: limit,
        search: watch("search"),
      },
    ],
    getDiscussionForum
  );

  const handlePagination = (e: any, newPage: number) => {
    setoffset(newPage * limit);
  };
  const handlePaginationLimit = (e: any) => {
    setoffset(0);
    setlimit(e.target.value);
  };

  const column = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "_id",
      render: (e: any) => (
        <Link onClick={() => navigate(e)} sx={{ textDecoration: "none" }}>
          View
        </Link>
      ),
    },
  ];
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Box sx={{ maxWidth: "350px" }}>
          <FormOutlinedInput
            control={control}
            name="search"
            placeholder="Search by Title..."
          />
        </Box>
        <TableComponent
          columns={column}
          headAlign={"center"}
          tableAlign={"center"}
          dataSource={data?.data?.data}
          loading={false}
          pagination={{
            page: offset / limit,
            rowsPerPage: limit,
            count: data?.data?.count,
            onPageChange: handlePagination,
            onRowsPerPageChange: handlePaginationLimit,
          }}
        />
      </Box>
      <Continue />
    </Box>
  );
}

export default MyDiscussionForum;
