import EditIcon from "@mui/icons-material/Edit";
import { Box, IconButton } from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { updateProfileImage } from "../../../../api/services/userProfile";

const ProfileImageUpload = ({ imageUrl }) => {
  const [pic, setPic] = useState<any>(null);
  const queryClient = useQueryClient();

  const { mutate } = useMutation(updateProfileImage, {
    onSuccess: (res: any) => {
      toast.success("Image updated successfully");
      queryClient.invalidateQueries("studentProfile");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const handleImageChange = async (e: any) => {
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("type", "STUDENT");
    formData.append("subType", "DOCS");

    try {
      const response = await axios({
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/s3-resources/file-upload`,
        data: formData,
      });
      console.log("response?.data", response?.data);
      if (response?.data?.key) {
        mutate({ imageKey: response.data.key });
      } else {
        throw new Error("Upload failed or key not returned");
      }
    } catch (error) {
      toast.error("Error");
    }
  };
  return (
    <Box
      sx={{
        height: "125px",
        width: "125px",
        borderRadius: "16px",
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}
    >
      <IconButton
        sx={{
          zIndex: 999,
          color: "white",
          position: "absolute",
          bottom: 0,
          right: 0,
          backgroundColor: "#f4347f",
          borderRadius: 0,
          borderBottomRightRadius: "16px",
          borderTopLeftRadius: "16px",
          ":hover": { backgroundColor: "#f4347f" },
        }}
        component="label"
      >
        <input
          onChange={handleImageChange}
          type="file"
          style={{ display: "none" }}
          id={"profile"}
          accept={"image/*"}
        />
        <EditIcon sx={{ height: "18px", width: "18px" }} />
      </IconButton>
    </Box>
  );
};

export default ProfileImageUpload;
