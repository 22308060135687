export function capitalize(text) {
  return text
    ?.split(" ")
    ?.map(
      (string: string) =>
        string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
    )
    ?.join(" ");
}

export function formatTime(seconds) {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);
  let timeString = "";
  if (h > 0) {
    timeString += `${h} Hr `;
  }
  if (m > 0) {
    timeString += `${m} Min `;
  }
  if (s > 0) {timeString += `${s} Sec`}
  return timeString.trim();
}
