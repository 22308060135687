import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getAllNotes } from "../../../../api/services/learning";
import NoteCard from "../../../MyLearningPage/components/NoteCard";

const PersonalNotes = () => {
  const { data, isLoading } = useQuery("All-personal-notes", getAllNotes);
  return (
    <Box>
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: 600,
          color: "#2F468C",
          py: 2,
        }}
      >
        Your Notes
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {data?.data?.length > 0 ? (
          data?.data?.map((item: any) => (
            <NoteCard key={item._id} data={item} />
          ))
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: 600,
              py: 5,
            }}
          >
            No Notes Found
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PersonalNotes;
