import { Box, Button, Stack, Typography } from "@mui/material";
import LineText from "../../../Components/LineText";
import { StyledAbsoleteButton } from "./EBooks";

function OnlineExam() {
  return (
    <Box sx={{ marginY: "20px" }}>
      <LineText title={"Online Exam"} />
      <ExamCard />
    </Box>
  );
}

const ExamCard = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          height: "160px",
          padding: "10px",
          marginY: "20px",
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "30px" }}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "120px",
                  sm: "120px",
                  lg: "220px",
                },
                borderRadius: "10px",
                backgroundColor: "#3E348E29",
                height: "140px",
              }}
            >
              <StyledAbsoleteButton
                sx={{
                  width: {
                    xs: "100px",
                    sm: "100px",
                    lg: "220px",
                  },
                }}
              >
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      lg: "20px",
                    },
                  }}
                >
                  Online Test
                </Typography>
              </StyledAbsoleteButton>
            </Box>
            <Stack
              alignItems={"flex-start"}
              justifyContent={"center"}
              gap={"10px"}
            >
              <Box
                sx={{
                  backgroundColor: "#2F468C",

                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: {
                      xs: "15px",
                      sm: "15px",
                      lg: "20px",
                    },
                  }}
                >
                  Electrician
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    lg: "20px",
                  },
                }}
                variant="h5"
              >
                1 Hr 30 min
              </Typography>
              <Typography
                sx={{ fontSize: "14px", color: "rgba(34, 34, 34, 0.5)" }}
                variant="subtitle2"
              >
                1 Hr 30 min
              </Typography>
            </Stack>
          </Box>
        </Box>
        <Button variant="contained" sx={{ paddingX: "30px", paddingY: "23px" }}>
          Start Test
        </Button>
      </Box>
    </>
  );
};

export default OnlineExam;
