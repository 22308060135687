import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getOneEBook } from "../../api/services/ebooks";
import { capitalize } from "../../utils";
import Header from "../SignInPages/Header";
import { StyledMainContentContainer } from "../SignInPages/Sign-in-Layout";

const EBook = () => {
  const { id } = useParams();
  const { data, isLoading } = useQuery(["getOneEBook", id], getOneEBook);
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <StyledMainContentContainer
        sx={{
          width: `100vw`,
          top: "64px",
          left: 0,
          height: `calc(100vh - ${"64px"})`,
          overflowX: "hidden",
        }}
      >
        <Button
          disableRipple
          variant="text"
          startIcon={<KeyboardArrowLeftIcon />}
          sx={{ ":hover": { backgroundColor: "transparent" }, py: 0 }}
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            px: 2,
            textAlign: "center",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h6">{capitalize(data?.data?.title)}</Typography>
          <Box
            sx={{
              "& iframe": {
                width: "90vw !important",
                height: "78vh !important",
              },
              alignContent: "center",
            }}
            dangerouslySetInnerHTML={{
              __html: data?.data?.link,
            }}
          />
        </Box>
      </StyledMainContentContainer>
    </>
  );
};

export default EBook;
