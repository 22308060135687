import { Box, Chip, IconButton, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { exam, leftArrow, rightArrow } from "../../../assets";

function Examcard({ data, tradeName, sx }: any) {
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 6px #11234B29",
        border: "1px solid #cecece76",
        width: "300px",
        height: "280px",
        justifyContent: "center",
        marginBottom: "20px",
        cursor: "pointer",
        borderRadius: "15px",
        ...sx,
      }}
    >
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box
          sx={{
            width: "300px",
            height: "180px",
          }}
        >
          <img
            src={exam}
            alt="course img"
            width={"298px"}
            height={"100%"}
            style={{
              objectFit: "contain",
              borderTopRightRadius: "15px",
              borderTopLeftRadius: "15px",
            }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "#2F468C",
            padding: "6px",
            borderRadius: "0px 15px",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px", px: 1 }}>
            {tradeName}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "#2F468C",
            p: "6px",
            borderRadius: "0px 15px 0px 0px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px" }}>
            Online Test
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
          p: 1,
          px: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "16px" }}>
          {data?.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: "14px",
              width: "max-content",
            }}
          >
            {`${data?.parts?.length} Parts`}
          </Typography>
          <Chip
            label={data?.difficultyLevel}
            color={
              data?.difficultyLevel === "EASY"
                ? "success"
                : data?.difficultyLevel === "MEDIUM"
                ? "warning"
                : "error"
            }
            sx={{
              fontSize: "12px",
              height: "25px",
              fontFamily: "figtree_medium",
            }}
          />
        </Box>

        <Box
          sx={{
            fontFamily: "figtree_medium",
            "& *": { p: 0, m: 0 },
            fontSize: "14px",
            opacity: 0.8,
            textOverflow: "ellipsis",
            pt: "3px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              data?.description?.length > 40
                ? data?.description.substring(0, 40) + "..."
                : data?.description,
          }}
        ></Box>
      </Box>
    </Box>
  );
}

const ExamCarousel = ({ data, tradeName }: any) => {
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [slidesPerView, setSlidesPerView] = useState(
    getSlidesPerView(window.innerWidth)
  );

  function getSlidesPerView(width: number): number {
    return Math.max(1, Math.floor(width / 365));
  }

  useEffect(() => {
    const handleResize = () => {
      setSlidesPerView(getSlidesPerView(window.innerWidth));
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", position: "relative", marginBottom: "20px" }}>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={5}
        slidesPerView={slidesPerView}
        loop={true}
        autoplay={{ delay: 500, disableOnInteraction: false }}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
        }}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
      >
        {data?.exams?.map((item: any, idx: any) => (
          <SwiperSlide>
            <Examcard
              data={item}
              tradeName={tradeName}
              sx={{ marginRight: 3 }}
              key={idx}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton ref={prevRef}>
          <img
            style={{ width: "35px", height: "35px" }}
            src={leftArrow}
            alt="leftArrow"
          />
        </IconButton>
        {data?.exams?.map((_, bulletIdx) => (
          <Box
            key={bulletIdx}
            sx={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: "#F4347F",
              opacity: bulletIdx === activeIndex ? "100%" : "30%",
              margin: "15px 0px",
              cursor: "pointer",
            }}
          />
        ))}
        <IconButton ref={nextRef}>
          <img
            style={{ width: "35px", height: "35px" }}
            src={rightArrow}
            alt="rightArrow"
          />
        </IconButton>
      </Box>
    </Box>
  );
};

export default ExamCarousel;
