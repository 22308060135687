import { Box } from "@mui/material";
import { useState } from "react";
import { AboutExam } from "./aboutExam";
import { Instructions } from "./instructions";

function Test() {
  const [value, setValue] = useState("aboutExam");
  return (
    <>
      <Box>
        {value === "aboutExam" && (
          <AboutExam setValue={setValue} />
        )}
        {value === "instructions" && <Instructions  />}
      </Box>
    </>
  );
}

export default Test;
