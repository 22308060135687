import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled as muiStyled,
  useMediaQuery,
} from "@mui/material";
import { Outlet, useLocation } from "react-router-dom";

import { Close, MenuOpen } from "@mui/icons-material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { getStudentProfile } from "../../api/services";
import Header from "./Header";
import SideNav from "./SideNav";

function SigninLayout() {
  const [Sidebar, setSidebar] = useState(false);
  const [Sidebarnotation, setSidebarnotation] = useState(false);
  const isLessThan1200 = useMediaQuery("(max-width: 1200px)");
  const location = useLocation();
  const hideNotionalHours = location.pathname.endsWith("/my-dashboard");
  const profile = location.pathname.endsWith("/profile");
  const { data: studentData } = useQuery("studentProfile", getStudentProfile);

  return (
    <>
      <Header />
      {isLessThan1200 ? (
        <>
          <Drawer
            sx={{
              "& .MuiDrawer-paper": {
                width: "90%",
                height: "100%",
                borderRadius: "10px",
                backgroundColor: "#2F468C",
              },
            }}
            anchor="left"
            open={Sidebar}
            onClose={() => setSidebar(false)}
          >
            <Box
              sx={{
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography color={"white"} variant="h4">
                Menu Items
              </Typography>
              <IconButton
                onClick={() => {
                  setSidebar(false);
                }}
              >
                <Close sx={{ fontSize: "35px" }}></Close>
              </IconButton>
            </Box>
            <StyledColorHeaderBox data={studentData} />
            <SideNav setSidebar={setSidebar} />
          </Drawer>

          <Drawer
            sx={{
              "& .MuiDrawer-paper": {
                marginTop: "30px",
                height: "72vh",
                borderRadius: "10px",
                backgroundColor: "#2F468C", // Adjust border radius as needed
              },
            }}
            anchor="right"
            open={Sidebarnotation}
            onClose={() => setSidebarnotation(false)}
          >
            <Box
              sx={{
                margin: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography color={"white"} variant="h4">
                Progress
              </Typography>
              <IconButton
                onClick={() => {
                  setSidebarnotation(false);
                }}
              >
                <Close sx={{ fontSize: "35px" }}></Close>
              </IconButton>
            </Box>
            <NotionalHours data={studentData?.data?.notionalHours} />
          </Drawer>

          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <IconButton onClick={() => setSidebar(true)}>
                <MenuOpen
                  sx={{
                    fontSize: "30px",
                  }}
                ></MenuOpen>
              </IconButton>
              {!hideNotionalHours && !profile && (
                <Button onClick={() => setSidebarnotation(true)}>
                  Course Progress
                </Button>
              )}
            </Box>
            <Outlet />
          </Box>
          <LayoutWrapper data={studentData} />
        </>
      ) : (
        <LayoutWrapper data={studentData}>
          <Outlet />
        </LayoutWrapper>
      )}
    </>
  );
}

export default SigninLayout;

export function LayoutWrapper({ children, data }: any) {
  const isLessThan1200 = useMediaQuery("(max-width: 1200px)");
  const location = useLocation();
  const hideNotionalHours = location.pathname.endsWith("/my-dashboard");
  const profile = location.pathname.endsWith("/profile");
  return (
    <>
      {isLessThan1200 ? (
        <>
          {/* <StyledMainContentContainer>
            <StyledColorHeaderBox />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                margin: "10px",
              }}>
              <Box border={"2px solid red"}>
               
                {children}
              </Box>
            </Box>
          </StyledMainContentContainer> */}
        </>
      ) : (
        <>
          <StyledLeftNavContainer>
            <SideNav />
          </StyledLeftNavContainer>

          <StyledMainContentContainer>
            {!hideNotionalHours && !profile && (
              <StyledColorHeaderBox
                sx={{
                  position: "fixed",
                  width: `calc(98% - 215px)`,
                  zIndex: 10000,
                }}
                data={data}
              />
            )}
            <Stack sx={{ marginTop: !hideNotionalHours && !profile ? 24 : 0 }}>
              <Box
                sx={{
                  width:
                    !hideNotionalHours && !profile
                      ? `calc(100% - 245px)`
                      : "100%",
                  px: !hideNotionalHours && !profile ? 2 : 0,
                }}
              >
                {children}
              </Box>
              {!hideNotionalHours && !profile && (
                <NotionalHours
                  sx={{
                    position: "fixed",
                    right: 0,
                  }}
                  data={data?.data?.notionalHours}
                />
              )}
            </Stack>
          </StyledMainContentContainer>
        </>
      )}
    </>
  );
}

export const StyledColorHeaderBox = ({ sx, data }: any) => {
  return (
    <StyledColorBox sx={{ ...sx }}>
      <Stack direction={"row"} width={"100%"}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 2,
            py: "5px",
            gap: 1,
          }}
        >
          <Stack
            direction={"row"}
            gap={2}
            alignItems={"center"}
            justifyContent={"start"}
            width={"30%"}
          >
            <Box
              sx={{
                width: "100px",
                height: "100px",
                backgroundColor: "#FFFFFF",
                borderRadius: "10px",
              }}
            >
              <img
                src={data?.data?.imageUrl}
                width={"100px"}
                height={"100px"}
                style={{
                  objectFit: "cover",
                  borderRadius: "10px",
                  border: "2px solid white",
                }}
                alt="Test"
              />
            </Box>
            <Stack gap={1}>
              <Typography
                color={"white"}
                variant="h4"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                Hi, {data?.data?.name}
              </Typography>
              <Typography
                color={"white"}
                variant="body2"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                }}
              >
                {data?.data?.year?.name}
              </Typography>
              <Box
                sx={{
                  padding: "5px",
                  px: "10px",
                  backgroundColor: "#2F468C",
                  borderRadius: "25px",
                  width: "130px",
                  minWidth: "max-content",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography color={"white"}>
                  {data?.data?.trade?.name}
                </Typography>
              </Box>
            </Stack>
          </Stack>

          <Box
            sx={{
              display: "flex",
              gap: "5px",
              width: "max-content",
              py: "2px",
            }}
          >
            <Typography sx={{ fontSize: "12px", color: "white" }}>
              Last Login :
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>
              {moment(data?.data?.lastLoggedIn, "DD-MM-YYYY hh-mm-ss").format(
                "DD/MM/YYYY hh:mm A"
              )}
            </Typography>
          </Box>
        </Box>

        <Grid container sx={{ pl: 10 }}>
          <Grid item xs={6} sx={{ alignContent: "center" }}>
            <TitleBox
              title={"Admission Number"}
              value={data?.data?.studentId}
            />
          </Grid>
          <Grid item xs={6} sx={{ alignContent: "center" }}>
            <TitleBox
              title={"Assigned ITI Centre"}
              value={data?.data?.centre?.name}
            />
          </Grid>
          <Grid item xs={6} sx={{ alignContent: "center" }}>
            <TitleBox
              title={"Last Week Achieved"}
              value={`${
                Math.round((data?.data?.lastWeekAchieved / 3600) * 100) / 100
              } hr / 9 hr`}
            />
          </Grid>
          <Grid item xs={6} sx={{ alignContent: "center" }}>
            <TitleBox
              title={"This Week's Target"}
              value={`${
                Math.round((data?.data?.thisWeekAchieved / 3600) * 100) / 100
              } hr / 9 hr`}
            />
          </Grid>
        </Grid>
      </Stack>
    </StyledColorBox>
  );
};

export const NotionalBox = ({ title, value }: any) => {
  return (
    <>
      <Stack
        sx={{
          backgroundColor: "white",
          height: { xs: "70px", sm: "70px", lg: "65px", xl: "65px" },
          width: "100%",
          borderRadius: "10px",
          padding: "10px",
          marginBottom: "12px",
        }}
        gap={"5px"}
      >
        <Typography
          sx={{
            fontSize: {
              xs: "12px",
              sm: "12px",
              lg: "15px",
              xl: "15px",
            },
            fontWeight: 600,
          }}
        >
          {title}
        </Typography>
        <Typography
          sx={{
            color: "#2F468C",
            fontSize: {
              xs: "14px",
              sm: "14px",
              lg: "17px",
              xl: "17px",
            },
            fontWeight: 600,
          }}
        >
          {value}
        </Typography>
      </Stack>
    </>
  );
};

export const TitleBox = ({ title, value }: any) => {
  return (
    <Stack>
      <Typography
        sx={{
          color: "rgba(255, 255, 255, 0.8)",
          fontSize: "14px",
          opacity: "80%",
        }}
      >
        {title}
      </Typography>
      <Typography sx={{ color: "white", fontSize: "18px" }}>{value}</Typography>
    </Stack>
  );
};

export const headerHeight = "60px";
export const sideNavWidth = "215px";

export const StyledColorBox = muiStyled(Box)({
  backgroundColor: "#16ACAB",
  borderRadius: "10px",
  margin: "16px",
  // height: "140px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  padding: "5px",
});

export const StyledMainContentContainer = muiStyled("main")(() => ({
  width: `calc(100% - ${sideNavWidth})`,
  height: `calc(100vh - ${headerHeight})`,
  position: "fixed",
  top: headerHeight,
  left: sideNavWidth,
  overflowY: "auto",
}));

export const StyledLeftNavContainer = muiStyled("aside")(({ theme }) => ({
  width: sideNavWidth,
  background: "#2F468C",
  color: "white",
  position: "fixed",
  // top: headerHeight,
  top: `calc( ${headerHeight} + "3px" )`,
  left: 0,
  height: `calc(100vh - ${headerHeight})`,
  overflowY: "auto",

  "&::-webkit-scrollbar": {
    width: "0.5em",
    height: "0.5em",
  },

  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "3px",

    "&:hover": {
      background: "rgba(255, 255, 255, 0.4)",
    },
  },

  zIndex: 100,
}));

export const NotionalHours = ({ sx, data }: any) => {
  return (
    <>
      <Box
        sx={{
          borderRadius: "10px",
          backgroundColor: "#2F468C",
          marginRight: "18px",
          // width: { lg: "16%" },
          // maxWidth: "250px",
          width: "230px",
          ...sx,
          // height: {
          //   xs: "60vh",
          //   sm: "60vh",
          //   lg: "70vh",
          //   xl: "70vh",
          // },
        }}
      >
        <Stack alignItems={"center"} sx={{ padding: "5px", paddingY: 1 }}>
          <Typography
            color={"white"}
            variant="h4"
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                lg: "20px",
                xl: "20px",
              },
            }}
          >
            Notional Hours
          </Typography>
          <Typography
            color={"white"}
            variant="h5"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "12px",
                lg: "14px",
                xl: "14px",
              },
            }}
          >
            Achieved vs Target
          </Typography>
        </Stack>
        <Divider sx={{ my: 1, borderColor: "white", opacity: "0.3" }} />
        <Box sx={{ px: 2, py: 1 }} alignItems={"center"}>
          {data?.map((item: any) => (
            <NotionalBox
              title={`${item?.bundle?.name}`}
              value={`${
                Math.round((item?.achievedHours / 3600) * 100) / 100
              } hr / ${item?.targetHours} hr`}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};
