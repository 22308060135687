import { Box, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getStudentProfile } from "../../../../api/services";
import {
  EmployabilitySkills,
  EngineeringDrawing,
  TradePractical,
  TradeTheory,
  Workshop,
} from "../../../../assets";
import Continue from "../../components/Continue";
import { StyledColorHeaderBox } from "../../Sign-in-Layout";
import ProgressGraph from "./components/ProgressGraph";
import TestAndBooks from "./components/TestAndBooks";

const MyDashboard = () => {
  const { data: studentData } = useQuery("studentProfile", getStudentProfile);
  const accessToken = localStorage.getItem("access_token");
  const navigate = useNavigate();

  // const uploadFiles = () => {
  //   console.log("1");
  //   if (
  //     accessToken &&
  //     studentData?.data?.sscKey !== null &&
  //     studentData?.data?.aadharKey !== null &&
  //     studentData?.data?.imageKey !== null &&
  //     studentData?.data?.signatureKey !== null
  //   ) {
  //     console.log("2");

  //     navigate("/upload-files", { state: { accessToken: accessToken } });
  //   }
  // };

  // useEffect(() => {
  //   uploadFiles();
  // }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <StyledColorHeaderBox data={studentData} />
      <Box
        sx={{
          width: "100%",
          px: 2,
          display: "flex",
          gap: 3,
          flexDirection: "column",
          pb: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            background:
              "transparent linear-gradient(180deg, #2F468C 0%, #5973C7 100%) 0% 0% no-repeat padding-box",
            borderRadius: "10px",
            height: "150px",
          }}
        >
          <Typography
            sx={{ color: "white", textAlign: "center", py: 4 }}
            variant="h4"
          >
            Notional Hours (Achieved vs Target)
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              gap: 3,
            }}
          >
            {studentData?.data?.notionalHours?.map((item: any) => (
              <StyledBox
                title={`${item?.bundle?.name}`}
                notionalHours={`${
                  Math.round((item?.achievedHours / 3600) * 100) / 100
                } hr / ${item?.targetHours} hr`}
              />
            ))}
          </Box>
        </Box>
        <Continue sx={{ marginTop: 12 }} />
        <ProgressGraph />
        <TestAndBooks />
      </Box>
    </Box>
  );
};

export default MyDashboard;

const StyledBox = ({ title, notionalHours }: any) => {
  const image =
    title.trim() === "Trade Theory"
      ? TradeTheory
      : title.trim() === "Trade Practical"
      ? TradePractical
      : title.trim() === "Employability Skills"
      ? EmployabilitySkills
      : title.trim() === "Engineering Drawing"
      ? EngineeringDrawing
      : title.trim() === "Workshop Calculation"
      ? Workshop
      : "";
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "220px",
        padding: "15px 15px 20px 15px",
        gap: 1,
        borderRadius: "10px",
        backgroundColor: "white",
        boxShadow: "0px 6px 16px #12121229",
      }}
    >
      <Box sx={{ height: "45px", width: "45px" }}>
        <img src={image} alt="icon" height={"100%"} width={"100%"} />
      </Box>
      <Typography sx={{ fontWeight: 600 }}>{title}</Typography>
      <Typography sx={{ color: "#2F468C", fontWeight: 600, fontSize: "19px" }}>
        {notionalHours}
      </Typography>
    </Box>
  );
};
