// import 'katex/dist/katex.min.css';
import { useMemo, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.core.css";
import "react-quill/dist/quill.snow.css";

import axios from "axios";
import katex from "katex";
import { toast } from "react-toastify";

// import 'katex/dist/katex.min.css';
window.katex = katex;

type QuillProps = {
  data: string;
  handleChange: (value: any) => void;
  placeholder?: string;
  minHeight?: string;
  hasImage?: boolean;
};

const Quill = ({
  data,
  handleChange,
  placeholder,
  minHeight = "200px",
  hasImage = false,
}: QuillProps) => {
  const ref: any = useRef();

  const handleImageUpload = async () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files ? input.files[0] : null;
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("type", "STUDENT");
        formData.append("subType", "DOUBT");

        try {
          const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_URL}/s3-resources/file-upload`,
            data: formData,
          });
          console.log("response?.data", response?.data?.s3Response?.url);
          const imageUrl = response?.data?.s3Response?.url;
          const quillEditor = ref.current.getEditor();
          const range = quillEditor.getSelection();
          quillEditor.insertEmbed(range.index, "image", imageUrl);
        } catch (error) {
          toast.error("Image upload failed");
          console.log(error);
        }
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        clipboard: {
          matchVisual: false,
        },
        container: [
          [{ header: [1, 2, false] }],
          ["bold", "italic", "underline", hasImage ? "image" : ""],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          [{ color: [] }],
          ["clean"],
          ["formula", "code"],
        ],
        handlers: {
          image: handleImageUpload,
        },
      },
    }),
    []
  );

  return (
    <ReactQuill
      placeholder={placeholder}
      ref={ref}
      theme={"snow"}
      style={{ zIndex: 9999, minHeight }}
      value={data}
      modules={modules}
      onChange={(content: any) => {
        handleChange(content);
      }}
    />
  );
};

export default Quill;
