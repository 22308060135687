import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { getCourseEbooks } from "../../../api/services";
import { NoData } from "../../../assets";
import { Loader } from "../../../components/loader";
import { StyledHead } from "../../../components/styledHead";
import { EBookCard } from "../../SignInPages/Pages/MyEBooks/components/EBookCard";

const EBooks = ({ sx }: any) => {
  const location = useLocation();
  const params = useParams();
  const searchParams = new URLSearchParams(location.search);
  const currentTab = searchParams.get("tab");

  console.log("params", params);

  const topicId = searchParams.get("topicId");

  const { data, isLoading } = useQuery(
    ["get-ebooksby-courses ", params?.bundleId, params?.courseId],
    getCourseEbooks
  );

  const eBookData = data?.data?.ebooks;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 2,
            gap: 1,
            ...sx,
          }}
        >
          {eBookData?.length > 0 ? (
            <>
              <StyledHead
                head={"E-Books"}
                color="black"
                sx={{ height: "30px" }}
              />
              {eBookData?.map((item: any, index: any) => {
                return (
                  <>
                    <EBookCard data={item} />
                    {index < eBookData.length - 1 && <Divider />}
                  </>
                );
              })}
            </>
          ) : currentTab === "books" ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "80vh",
              }}
            >
              <Box sx={{ alignItems: "center", width: "500px" }}>
                <img
                  src={NoData}
                  alt="No Data"
                  height={"100%"}
                  width={"100%"}
                />
              </Box>
              <Typography variant="h4" sx={{ py: 1 }}>
                No E-Books Found
              </Typography>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default EBooks;
