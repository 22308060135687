import { http } from "../http"

export const getBundleTypes = ()=>{
    return http.get("/students/bundle-types");
}

export const getEBooks = ({ queryKey }: any) => {
  return http.get(`/e-books/student/${queryKey[1]}`);
};

export const getOneEBook = ({ queryKey }: any) => {
  return http.get(`/e-books/${queryKey[1]}`);
};

