import { IconButton, styled } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import { icons } from "../assets";
import {
  StyledLeftNavContainer,
  StyledMainContentContainer,
} from "../pages/HomePage/styles";
import MyLearning from "../pages/MyLearningPage";
import MyLearningSideNav from "../pages/MyLearningPage/components/MyLearningSideNav";
import Header from "../pages/SignInPages/Header";

export const ListOfCoursesLayout = () => {
  const [open, setOpen] = useState<boolean>(true);
  return (
    <>
      <Header />
      {open && (
        <StyledLeftNavContainer
          sx={{
            width: "350px",
            background: "white",
            top: "70px",
            height: `calc(100vh - ${"70px"})`,
            boxShadow: "0px 3px 6px #00000029",
          }}
        >
          <MyLearningSideNav setToggle={setOpen} />
        </StyledLeftNavContainer>
      )}
      {!open && (
        <StyledLeftNavContainer
          sx={{
            width: "70px",
            background: "white",
            top: "70px",
            height: `calc(100vh - ${"70px"})`,
          }}
        >
          <IconButton
            sx={{
              borderRadius: "0px",
              width: "45px",
              height: "45px",
              mx: 2,
              // border: "1px solid black",
              boxShadow: "0px 5px 15px #22222214",
            }}
            onClick={() => setOpen(true)}
          >
            <img
              src={icons.ExpandIcon}
              style={{ width: "25px", height: "25px" }}
            />
          </IconButton>
        </StyledLeftNavContainer>
      )}
      <StyledMainContentContainer
        sx={{
          width: open ? `calc(100vw - ${"350px"})` : `calc(100vw - ${"70px"})`,
          left: open ? "350px" : "70px",
          top: "70px",
        }}
      >
        <MyLearning isOpen={open}/>
      </StyledMainContentContainer>
    </>
  );
};

export const StyledLink = styled(Link)(({ theme }) => ({
  fontSize: "20px",
  textDecoration: "none",
}));
