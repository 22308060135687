import { Close } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Rating,
  Typography,
} from "@mui/material";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ReactPlayer from "react-player";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getTopicComponents,
  nextVideo,
  postReview,
  videoLog,
} from "../../../api/services/learning";
import { NoData, rating } from "../../../assets";
import FormOutlinedInput from "../../../components/FormFields/FormOutlinedInput";
import { Loader } from "../../../components/loader";
import { formatTime } from "../../../utils";
import Doubts from "./Doubts";
import Notes from "./Notes";
import EBooks from "./Ebooks";
import Quiz from "./Quiz";

const Learn = ({ isOpen }: any) => {
  const { bundleId, courseId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const topicId = searchParams.get("topicId");
  const chapterId = searchParams.get("chapterId");
  const sectionId = searchParams.get("sectionId");
  const playerRef = useRef(null);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [stars, setStars] = useState<number | null>(0);
  const [isNotes, setIsNotes] = useState<boolean>(true);

  const { control, getValues } = useForm({
    defaultValues: {
      course: courseId,
      description: "",
    },
  });

  const { mutate: reviewMutate } = useMutation(postReview, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = () => {
    const postData = {
      courseId: courseId,
      rating: stars,
      description: getValues("description"),
    };
    setOpen(false);
    reviewMutate(postData);
  };

  const { data, isLoading } = useQuery(
    ["getTopicComponents", topicId],
    getTopicComponents
  );

  const videoData = data?.data?.filter(
    (item: any) => item?.type === "VIDEO"
  )[0];

  const { mutate: paused } = useMutation(videoLog, {
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const { mutate: completed } = useMutation(videoLog, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
      queryClient.invalidateQueries("getTopicComponents");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const { mutate } = useMutation(nextVideo, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
      queryClient.invalidateQueries("getTopicComponents");
      searchParams.set("chapterId", data?.data?.NextCourse?.chapterId);
      searchParams.set("sectionId", data?.data?.NextCourse?.sectionId);
      searchParams.set("topicId", data?.data?.NextCourse?.topicId);
      navigate(`?${searchParams.toString()}`);
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const handleLog = (status: any) => {
    const postData = {
      bundleId: bundleId,
      courseId: courseId,
      subjectId: videoData?.subjectId,
      chapterId: chapterId,
      sectionId: sectionId,
      topicId: topicId,
      status: videoData?.video?.status === "COMPLETED" ? "COMPLETED" : status,
      completedDuration: playerRef.current.getCurrentTime(),
    };
    if (status === "COMPLETED") {
      return completed(postData);
    } else if (
      playerRef.current.getDuration() - playerRef.current.getCurrentTime() >
      5
    ) {
      return paused(postData);
    }
  };

  const handleNextVideo = () => {
    const postData = {
      bundleId: bundleId,
      courseId: courseId,
      subjectId: videoData?.subjectId,
      chapterId: chapterId,
      sectionId: sectionId,
      topicId: topicId,
    };
    mutate(postData);
  };

  const handleSeek = () => {
    if (playerRef.current) {
      playerRef.current.seekTo(videoData?.video?.completedDuration, "seconds");
    }
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : videoData?.link ? (
        <Box
          sx={{
            px: 2,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F4347F",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ p: 1, color: "white", fontSize: "16px" }}>
              {videoData?.topic?.title}
            </Typography>
            <Typography sx={{ p: 1, color: "white", fontSize: "16px" }}>
              {formatTime(videoData?.videoDuration)}
            </Typography>
          </Box>
          <ReactPlayer
            style={{ backgroundColor: "black" }}
            width="100%"
            height={`calc(100vh - ${"240px"})`}
            ref={playerRef}
            url={"https://vimeo.com/141165330"}
            controls
            onReady={handleSeek}
            onEnded={() => {
              return handleLog("COMPLETED");
            }}
            onPause={() => {
              return handleLog("UNLOCKED");
            }}
          />

          {isNotes ? (
            <Notes sx={{ p: "5px 0px 25px 0px" }} />
          ) : (
            <Doubts sx={{ p: "5px 0px 25px 0px" }} />
          )}

          <EBooks sx={{ p: "5px 0px 25px 0px" }} />

          <Quiz sx={{ p: "5px 0px 25px 0px" }} />

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              position: "fixed",
              backgroundColor: "white",
              alignItems: "center",
              bottom: 2,
              py: "3px",
              zIndex: 98,
              width: isOpen
                ? `calc(100vw - ${"380px"})`
                : `calc(100vw - ${"110px"})`,
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
              <Button
                variant="outlined"
                sx={{ px: 2, borderRadius: "5px" }}
                onClick={() => setIsNotes(!isNotes)}
              >
                {isNotes ? "Ask a Doubt" : "Take Notes"}
              </Button>
              <Button
                variant="text"
                disableRipple
                sx={{
                  p: 0,
                  m: 0,
                  fontSize: "13px",
                  color: "#2F468C",
                  minWidth: "max-content",
                }}
                startIcon={<StarBorderOutlinedIcon />}
                onClick={() => setOpen(true)}
              >
                Leave a rating
              </Button>
            </Box>

            <Dialog
              maxWidth={"xs"}
              fullWidth
              open={open}
              onClose={() => setOpen(false)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  p: 2,
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => setOpen(false)}
                  sx={{ alignSelf: "end", height: "20px", width: "20px" }}
                >
                  <Close />
                </IconButton>
                <Box
                  sx={{
                    px: 2,
                    textAlign: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                  }}
                >
                  <Box
                    sx={{
                      width: "200px",
                      height: "100%",
                      mb: 1,
                      mx: "auto",
                    }}
                  >
                    <img
                      src={rating}
                      alt="rating"
                      height={"100%"}
                      width={"100%"}
                    />
                  </Box>
                  <Typography variant="h4">Rate your experience</Typography>
                  <Typography variant="body1" sx={{ textAlign: "center" }}>
                    Please let us know your feedback of the course. Your review
                    will help us improve.
                  </Typography>
                  <Rating
                    name="simple-controlled"
                    size="large"
                    value={stars}
                    sx={{ py: 1 }}
                    onChange={(event, newValue) => {
                      setStars(newValue);
                    }}
                  />
                  <FormOutlinedInput
                    name="description"
                    control={control}
                    placeholder="write your review…"
                    multiline={3}
                    sx={{ width: "100%", px: 2 }}
                  />
                  <Button
                    sx={{ width: "150px", my: 2 }}
                    variant="contained"
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </Dialog>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
              }}
            >
              <Typography sx={{ color: "#F4347F", fontSize: "14px" }}>
                Next Video
              </Typography>
              <IconButton
                sx={{
                  borderRadius: "5px",
                  backgroundColor: "#F4347F",
                  ":hover": { backgroundColor: "#F4347F" },
                  ":disabled": { backgroundColor: "#f4347f87" },
                }}
                disabled={
                  videoData?.video?.status === "COMPLETED" ? false : true
                }
                onClick={handleNextVideo}
              >
                <KeyboardArrowRightIcon sx={{ color: "white" }} />
              </IconButton>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Box sx={{ alignItems: "center", width: "500px" }}>
            <img src={NoData} alt="No Data" height={"100%"} width={"100%"} />
          </Box>
          <Typography variant="h4" sx={{ py: 1 }}>
            No Video Found
          </Typography>
        </Box>
      )}
    </>
  );
};

export default Learn;
