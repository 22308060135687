import { Box, Typography } from "@mui/material";
import moment from "moment";

const DoubtCard = ({ data }: any) => {
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 10px #0000001A",
        px: 2,
        py: 1,
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ fontSize: "14px", opacity: 0.5 }}>
          {`Posted on ${moment(data.createdAt).format(
            " DD MMM, YYYY - hh:mm A"
          )}`}
        </Typography>
      </Box>
      <Box
        sx={{ fontSize: "14px",fontFamily:"figtree_medium",'& img':{width:"75%"} }}
        dangerouslySetInnerHTML={{ __html: data?.comment }}
      >
      </Box>
      {data?.status === "RESOLVED" && (
        <Box
          sx={{
            backgroundColor: "#0E125E05",
            px: 1,
            my: 1,
            border: "1px solid #cecece",
            fontFamily: "figtree_medium",
            fontSize: "14px",
            borderRadius: "5px",
            "& *": { m: 0, py: 1 },
          }}
          dangerouslySetInnerHTML={{ __html: data?.reply?.comment }}
        ></Box>
      )}
    </Box>
  );
};

export default DoubtCard;
