import { Box, Typography, styled } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllDigitalLibrary } from "../../../api/services";
import { NoData } from "../../../assets";
import { Loader } from "../../../components/loader";

function ViweMore() {
  const { data, isLoading } = useQuery(
    "get-all-digital-library",
    getAllDigitalLibrary
  );
  const [selectedBtn, setSelectedBtn] = useState("");
  useEffect(() => {
    if (data && data.length > 0) {
      setSelectedBtn(data[0].name);
    }
  }, [data]);

  const Buttons = data?.map((item) => item.name) || [];

  const courseData = data?.find((item) => item.name === selectedBtn);
  return (
    <Box sx={{ py: 3, px: 5 }}>
      <Box
        sx={{
          display: "flex",
          gap: "5px",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#F4347F",
            width: "4px",
            height: "35px",
            borderRadius: "0px 5px 5px 0px",
          }}
        ></Box>
        <Typography variant="h4" sx={{ fontSize: "24px" }}>
          Explore
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            lg: "row",
            xl: "row",
          },
          width: "100vw",
          gap: "20px",
        }}
      >
        <Box>
          {Buttons?.map((item) => (
            <StyledButton
              key={item}
              sx={{
                bgcolor: item === selectedBtn ? "#F4347F" : "",
                color: item === selectedBtn ? "white" : "",
              }}
              onClick={(e) => setSelectedBtn(item)}
            >
              {item}
            </StyledButton>
          ))}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            px: 4,
            flexWrap: "wrap",
            gap: 3,
            pb: { xs: 8, lg: 0 },
          }}
        >
          {isLoading ? (
            <Loader />
          ) : (
            courseData?.courseBundles?.map((bundle: any) => (
              <Card bundle={bundle} sx={{ marginBottom: 0 }} />
            ))
          )}

          {courseData?.courseBundles?.length === 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 1,
                margin: "auto",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  width: { xs: "60vw", lg: "35vw" },
                  margin: "auto",
                }}
              >
                <img
                  src={NoData}
                  height={"100%"}
                  width={"100%"}
                  alt="No courses"
                />
              </Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  alignSelf: "center",
                }}
              >
                No courses available
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default ViweMore;

export const StyledButton = styled(Typography)({
  height: "40px",
  padding: "7px",
  paddingLeft: "15px",
  width: "100%",
  cursor: "pointer",
  minWidth: "250px",
});

function Card({ bundle, sx }: any) {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        boxShadow: "0px 3px 6px #11234B29",
        border: "1px solid #cecece76",
        width: "350px",
        height: "300px",
        justifyContent: "center",
        marginBottom: "20px",
        cursor: "pointer",
        borderRadius: "15px",
        ...sx,
      }}
      onClick={() => navigate(`/bundles/${bundle?._id}`)}
    >
      <Box sx={{ width: "100%", position: "relative" }}>
        <Box sx={{ width: "350px", height: "200px" }}>
          <img
            src={bundle?.imageUrl}
            alt="course img"
            width={"100%"}
            height={"100%"}
            style={{ objectFit: "contain", borderRadius: "15px" }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "#2F468C",
            padding: "6px",
            borderRadius: "0px 15px",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px", px: 1 }}>
            {bundle?.trade?.name}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            left: 0,
            backgroundColor: "#2F468C",
            p: "6px",
            borderRadius: "0px 15px 0px 0px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Typography sx={{ color: "white", fontSize: "12px" }}>
            Course Bundle
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "3px",
          p: 1,
          px: 1,
        }}
      >
        <Typography variant="h5" sx={{ fontSize: "16px" }}>
          {`${bundle?.trade?.code}-${bundle?.bundle?.name} ${bundle?.year?.name}`}
        </Typography>

        <Typography
          variant="h5"
          sx={{
            fontSize: "14px",
            py: "3px",
            px: 1,
            border: "1px solid #cecece",
            width: "max-content",
            borderRadius: "5px",
          }}
        >
          {`${bundle?.courses?.length} Courses`}
        </Typography>

        <Box
          sx={{
            fontFamily: "figtree_medium",
            "& *": { p: 0, m: 0 },
            fontSize: "14px",
            opacity: 0.8,
            textOverflow: "ellipsis",
            pt: "3px",
          }}
          dangerouslySetInnerHTML={{
            __html:
              bundle?.description?.length > 40
                ? bundle?.description.substring(0, 40) + "..."
                : bundle?.description,
          }}
        ></Box>
      </Box>
    </Box>
  );
}
