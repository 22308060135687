import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  beginTest,
  getExamData,
  getStudentProfile,
} from "../../api/services/test";
import { logo } from "../../assets";
import { Loader } from "../../components/loader";
import { TitleBox } from "../../components/TitleBox";
import {
  disableRightClick,
  handleKeyDown,
  openFullscreen,
} from "./instructions";

export const AboutExam = ({ setValue }: any) => {
  const { id } = useParams();

  const { data: examData, isLoading: examLoading } = useQuery(
    ["getExamData", id],
    getExamData
  );

  const { data: studentData } = useQuery("student-profile", getStudentProfile);

  // const { mutate, isLoading } = useMutation(beginTest, {
  //   onSuccess: (data) => {
  //     openFullscreen();
  //     setResponseId(data?._id);
  //     setValue("instructions");
  //   },
  //   onError: (e: any) => {
  //     toast.error(e.response.data.message || "Something went wrong");
  //     setTimeout(() => {
  //       navigate("/online-test");
  //     }, 3000);
  //   },
  // });

  // const handleSubmit = () => {
  //   try {
  //     const postBody = {
  //       userId: studentData?._id,
  //       examId: id,
  //     };
  //     mutate(postBody);
  //   } catch (error) {
  //     console.error("Error submitting data:", error);
  //   }
  // };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("contextmenu", disableRightClick);
  }, []);

  return (
    <Box
      sx={{
        height: `100vh`,
        width: "100%",
        py: 5,
        alignContent: "center",
      }}
    >
      {examLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            boxShadow: "0px 6px 16px #21468329",
            borderRadius: "15px",
            maxWidth: "1440px",
            p: 3,
            gap: 2,
            margin: "auto",
          }}
        >
          <img src={logo} width={220} height={80} alt="logo" />

          <Grid
            container
            spacing={1}
            rowGap={2}
            sx={{ textAlign: "left", px: 8 }}
          >
            <Grid item xs={12} sm={4}>
              <TitleBox title="Exam Title" value={examData?.data?.name} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Pass Percentage"
                value={`${examData?.data?.passPercentage} %`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Duration"
                value={`${examData?.data?.duration} Min`}
              />
            </Grid>

            {examData?.data?.parts?.map((item: any, index: any) => (
              <Grid item xs={12} sm={4} key={index}>
                <TitleBox title={`Part ${index + 1}`} value={item?.title} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="h4" fontSize="14px" sx={{ opacity: "0.6" }}>
                Short Description
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "figtree_semibold",
                  "& *": { p: 0, m: 0 },
                }}
                dangerouslySetInnerHTML={{
                  __html: examData?.data?.description,
                }}
              ></Typography>
            </Grid>
          </Grid>

          {/* <Box
            sx={{ pt: 6, pb: 2, bottom: 10, width: "100%", textAlign: "left" }}
          >
            <Divider sx={{ opacity: 0.4 }} />
            <Box sx={{ px: 8 }}>
              <Typography sx={{ pt: 2, fontSize: "16px", fontWeight: 600 }}>
                Declaration
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  py: "3px",
                  gap: 1,
                }}
              >
                <Checkbox
                  checked={checked}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ p: 0, m: 0 }}
                />

                <Typography
                  sx={{
                    fontSize: "14px",
                    fontFamily: "figtree_semibold",
                    "& *": {
                      margin: 0,
                      padding: 0,
                    },
                  }}
                  dangerouslySetInnerHTML={{
                    __html: examData?.data?.instructions,
                  }}
                ></Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 3,
              }}
            >
              <Button
                disabled={!checked || isLoading}
                onClick={handleSubmit}
                variant="contained"
                color="primary"
                sx={{ fontSize: "14px", px: 2 }}
              >
                I am ready to begin
              </Button>
            </Box>
          </Box> */}
          <Box sx={{ width: "100%", display: "absolute" }}>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                onClick={() => {
                  openFullscreen();
                  setValue("instructions");
                }}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
