import { Box, Button, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateDocuments } from "../../api/services/userProfile";
import { StyledHead } from "../../components/styledHead";
import UploadImage from "../../components/UploadImage";

const UploadForm = () => {
  const theme = useTheme();
  const [pic, setPic] = useState<any>(null);
  const [aadhar, setAadhar] = useState<any>(null);
  const [markSheet, setMarkSheet] = useState<any>(null);
  const [signature, setSignature] = useState<any>(null);
  const navigate = useNavigate();

  const { handleSubmit } = useForm();

  const { mutate } = useMutation(updateDocuments, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      localStorage.clear();
      navigate("/");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = async (formData: any) => {
    const res = {
      imageKey: pic?.key,
      aadharKey: aadhar?.key,
      sscKey: markSheet?.key,
      signatureKey: signature?.key,
    };

    if (
      pic === null ||
      aadhar === null ||
      markSheet === null ||
      signature === null
    ) {
      toast.error("Please upload your documents");
    } else {
      console.log("submitted");

      mutate(res);
    }
  };

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <StyledHead head="Student Details" /> */}
        {/* <StyledFlexBox flexDirection="column" gap={2} mt={2}> */}

        <Box>
          <StyledHead head="Mandatory Documents" />
          <Box mt={2} sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
            <Box justifyContent="space-between" gap={6}>
              <Box>
                <Typography>
                  Upload Photo <span style={{ color: "#F4347F" }}>*</span>
                </Typography>
                <Typography variant="caption">
                  *JPEG or PNG format Size less than 5 MB
                </Typography>
              </Box>
              <UploadImage
                pic={pic?.s3Response?.url}
                setPic={setPic}
                labelName="photo"
                accept="image/*"
                type="STUDENT"
                subType="DOCS"
              />
            </Box>
            <Box justifyContent="space-between" gap={6}>
              <Box>
                <Typography>
                  Upload Aadhar Copy <span style={{ color: "#F4347F" }}>*</span>
                </Typography>
                <Typography variant="caption">
                  *Other Options Driving License/Passport (JPEG or PNG format
                  Size 5 MB)
                </Typography>
              </Box>
              <UploadImage
                pic={aadhar?.s3Response?.url}
                setPic={setAadhar}
                labelName="aadhar"
                accept="/*"
                type="STUDENT"
                subType="DOCS"
              />
            </Box>
            <Box justifyContent="space-between" gap={6}>
              <Box>
                <Typography>
                  Upload 10th Marks Sheet{" "}
                  <span style={{ color: "#F4347F" }}>*</span>
                </Typography>
                <Typography variant="caption">
                  *PDF format Size less than 5 MB
                </Typography>
              </Box>
              <UploadImage
                pic={markSheet?.s3Response?.url}
                setPic={setMarkSheet}
                labelName="markSheet"
                accept="/*"
                type="STUDENT"
                subType="DOCS"
              />
            </Box>
            <Box justifyContent="space-between" gap={6}>
              <Box>
                <Typography>
                  Upload Signature <span style={{ color: "#F4347F" }}>*</span>
                </Typography>
                <Typography variant="caption">
                  *JPEG or PNG format Size less than 5 MB
                </Typography>
              </Box>
              <UploadImage
                pic={signature?.s3Response?.url}
                setPic={setSignature}
                labelName="signature"
                accept="/*"
                type="STUDENT"
                subType="DOCS"
              />
            </Box>
          </Box>
        </Box>
        <Box mt={3} textAlign="center">
          <Button variant="contained" type="submit">
            Submit
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default UploadForm;
