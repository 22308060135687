import styled from "@emotion/styled";
import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getExamsEBooks } from "../../../../../api/services";
import { exam, icons } from "../../../../../assets";
import { capitalize } from "../../../../../utils";

const TestAndBooks = () => {
  const { data } = useQuery("ExamsEBooks", getExamsEBooks);
  const navigate = useNavigate();
  return (
    <Box sx={{ display: "flex", flexDirection: "row", width: "100%", gap: 2 }}>
      <Box
        sx={{
          boxShadow: "0px 6px 16px #21468329",
          width: "50%",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography sx={{ fontWeight: 600, p: 2 }}>
          My CBT/Online Test
        </Typography>
        <Divider />
        {data?.data?.exams?.slice(0, 2)?.map((item: any) => (
          <Card data={item} type={"TEST"} />
        ))}
        <Box sx={{ marginTop: "auto" }}>
          <Divider />
          <Button
            variant="text"
            sx={{
              display: "flex",
              margin: "auto",
              px: 0,
              py: 3,
            }}
            onClick={() => {
              navigate("/online-test");
            }}
          >
            View All Tests
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          boxShadow: "0px 6px 16px #21468329",
          width: "50%",
          borderRadius: "5px",
        }}
      >
        <Typography sx={{ fontWeight: 600, p: 2 }}>My E-Books</Typography>
        <Divider />
        {data?.data?.ebooks?.slice(0, 2)?.map((item: any) => (
          <Card data={item} type={"EBOOK"} />
        ))}
        <Box sx={{ marginTop: "auto" }}>
          <Divider />
          <Button
            variant="text"
            sx={{
              display: "flex",
              margin: "auto",
              px: 0,
              py: 3,
            }}
            onClick={() => {
              navigate("/e-books");
            }}
          >
            View All E-Books
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default TestAndBooks;
const Card = ({ type, data }) => {
  const handleClick = () => {
    if (data?.examResponse?.status === "COMPLETE") {
      navigate(`/result/${data?._id}`);
    } else {
      navigate(`/test/${data?._id}`);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "140px",
          padding: "10px",
          pl: 2,
          pr: 4,
        }}
      >
        <Box>
          <Box sx={{ display: "flex", gap: "25px" }}>
            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "130px",
                  sm: "220px",
                  lg: "220px",
                },
                borderRadius: "10px",
                backgroundColor: "#3E348E29",
                height: "120px",
              }}
            >
              {type === "TEST" ? (
                <img src={exam} height={"100%"} width={"100%"} />
              ) : (
                <img
                  src={data?.fileUrl}
                  style={{ objectFit: "contain" }}
                  height={"100%"}
                  width={"100%"}
                />
              )}
              <StyledAbsoleteButton
                sx={{
                  width: {
                    xs: "100px",
                    sm: "130px",
                    lg: "110px",
                  },
                  gap: 1,
                }}
              >
                <img
                  src={type === "TEST" ? icons.test : icons.book}
                  alt="test"
                  style={{ width: "12px", height: "15px" }}
                />
                <Typography color={"white"} sx={{ fontSize: "14px" }}>
                  {type === "TEST" ? "Online Test" : "E-Book"}
                </Typography>
              </StyledAbsoleteButton>
            </Box>
            <Stack
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              gap={"10px"}
              pt={1}
            >
              <Box
                sx={{
                  backgroundColor: "#2F468C",
                  paddingX: "10px",
                  paddingY: "5px",
                  borderRadius: "20px",
                }}
              >
                <Typography
                  color={"white"}
                  sx={{
                    fontSize: "14px",
                    px: 1,
                  }}
                >
                  {data?.tradeName}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: "16px" }} variant="h5">
                  {type === "TEST"
                    ? capitalize(data?.name)
                    : capitalize(data?.title)}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "13px",
                    color: "rgba(34, 34, 34, 0.5)",
                    "& *": { p: 0, m: 0 },
                  }}
                  variant="subtitle2"
                  dangerouslySetInnerHTML={{
                    __html: data?.description,
                  }}
                ></Typography>
              </Box>
            </Stack>
          </Box>
        </Box>
        <Button
          variant="contained"
          sx={{
            paddingX: 2,
            paddingY: 2,
            alignItems: "center",
            fontSize: "14px",
            width: "130px",
          }}
          onClick={() => {
            type === "EBOOK" ? navigate(`/eBook/${data?._id}`) : handleClick();
          }}
        >
          {type === "TEST"
            ? data?.examResponse?.status === "COMPLETE"
              ? "View Report"
              : "Start Test"
            : "Start Reading"}
        </Button>
      </Box>
    </>
  );
};
const StyledAbsoleteButton = styled(Box)({
  backgroundColor: "#2F468C",
  borderRadius: "0px 10px",
  position: "absolute",
  left: 0,
  bottom: 0,
  padding: "5px",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});
