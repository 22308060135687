import { http } from "../http";

export const updateStudent = ({ id, data }: any) => {
  return http.patch(`/students/${id}`, data);
};

export const updatePassword = ({ data }: any) => {
  return http.patch(`students/change-password`, data);
};

export const updateProfileImage = (data: any) => {
  return http.patch(`/students/change-profile`, data);
};

export const updateDocuments = (data: any) => {
  return http.patch(`/students/upload-documents`, data);
};
