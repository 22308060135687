import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { getStudentProfile } from "../../../../api/services";
import {
  updatePassword,
  updateStudent,
} from "../../../../api/services/userProfile";
import DialogWrapper from "../../../../components/DialogWrapper";
import FormOutlinedInput from "../../../../components/FormFields/FormOutlinedInput";
import FormOutlinedPassword from "../../../../components/FormFields/FormOutlinedPassword";
import { StyledHead } from "../../../../components/styledHead";
import MediaCard from "./MediaCard";
import ProfileImageUpload from "./ProfileImageUpload";

const UserProfile = () => {
  const { data, isLoading } = useQuery("studentProfile", getStudentProfile);
  const [profile, setProfile] = useState(false);
  const [password, setPassword] = useState(false);
  const queryClient = useQueryClient();
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
      mobile: "",
      email: "",
      oldPassword: "",
      newPassword: "",
      reEnteredPassword: "",
      trade: "",
      centreType: "",
      centre: "",
    },
  });

  useEffect(() => {
    if (data?.data) {
      const { name, mobile, email, trade, centre, centreType, ...restData } =
        data?.data;
      reset({
        name,
        mobile,
        email,
        trade: trade?.name,
        centreType: centreType?.name,
        centre: centre?.name,
      });
    }
  }, [data]);

  const { mutate } = useMutation(updateStudent, {
    onSuccess: (res: any) => {
      toast.success("Student data updated successfully");
      queryClient.invalidateQueries("studentProfile");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { mutate: mutatePassword } = useMutation(updatePassword, {
    onSuccess: (res: any) => {
      toast.success("Password updated successfully");
      queryClient.invalidateQueries("studentProfile");
    },
    onError: (err: any) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const onFormSubmit = (formData: any) => {
    setProfile(false);
    if (formData.mobile?.length !== 10) {
      toast.error("Phone Number should be 10 digits");
      return;
    }
    if (formData.name?.length <= 0) {
      toast.error("Name should not be empty");
      return;
    }

    mutate({
      id: data?.data?._id,
      data: {
        name: formData.name,
        mobile: formData.mobile,
        email: formData.email,
        password: formData.password,
      },
    });
  };

  const onPasswordSubmit = (formData: any) => {
    setPassword(false);
    if (formData?.newPassword !== formData?.reEnteredPassword) {
      toast.error("Re-Enter the Password Correctly");
    } else {
      mutatePassword({
        data: {
          oldPassword: formData?.oldPassword,
          newPassword: formData?.newPassword,
        },
      });
    }
    reset();
  };

  return isLoading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "40vh",
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <Box sx={{ p: 3, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <StyledHead
          head={"Student Details"}
          sx={{ height: "35px" }}
          fontSize={"18px"}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
          <Button
            sx={{ width: "155px", p: 0, m: 0, fontSize: "14px" }}
            variant="outlined"
            onClick={() => setProfile(true)}
          >
            Update Profile
          </Button>
          <Button
            sx={{ width: "155px", p: 0, m: 0, fontSize: "14px" }}
            variant="outlined"
            onClick={() => setPassword(true)}
          >
            Change Password
          </Button>
        </Box>
      </Box>
      <Box sx={{ display: "flex", p: 2, gap: 2, alignItems: "flex-start" }}>
        <ProfileImageUpload imageUrl={data?.data?.imageUrl} />
        <Box
          sx={{ display: "flex", flexDirection: "column", py: 1, gap: "4px" }}
        >
          <Typography sx={{ fontWeight: 600 }}>{data?.data?.name}</Typography>
          <Typography>{data?.data?.email}</Typography>
          <Typography>{data?.data?.mobile}</Typography>
        </Box>
      </Box>

      <StyledHead
        head={"Trade Details"}
        sx={{ height: "35px" }}
        fontSize={"18px"}
      />
      <Box sx={{ py: 2, pl: 2, pb: 4 }}>
        <Grid container>
          <Grid item xs={2}>
            <LabelValue label={"Trade"} value={data?.data?.trade?.name} />
          </Grid>
          <Grid item xs={2}>
            <LabelValue
              label={"Practical Center Type"}
              value={data?.data?.centreType?.name}
            />
          </Grid>
          <Grid item xs={6}>
            <LabelValue
              label={"Practical Center Type"}
              value={data?.data?.centre?.name}
            />
          </Grid>
        </Grid>
      </Box>
      <StyledHead
        head={"Documents"}
        sx={{ height: "35px" }}
        fontSize={"18px"}
      />
      <Box sx={{ maxWidth: "750px", py: 2, pl: 2 }}>
        <Grid container columnSpacing={4} rowSpacing={2}>
          {data?.data?.docs?.length > 0 ? (
            data?.data?.docs?.map((item: any) => (
              <Grid item xs={6}>
                <MediaCard data={item} />
              </Grid>
            ))
          ) : (
            <Typography
              variant="body2"
              sx={{ opacity: "0.6", textAlign: "center" }}
            >
              No Documents Found
            </Typography>
          )}
        </Grid>
      </Box>

      <DialogWrapper open={profile} setOpen={setProfile} title="Update Profile">
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Box>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <FormOutlinedInput control={control} name="name" label="Name" />
              </Grid>
              <Grid item xs={12}>
                <FormOutlinedInput
                  control={control}
                  name="email"
                  label="Email"
                  type={"email"}
                />
              </Grid>
              <Grid item xs={12}>
                <FormOutlinedInput
                  type={"number"}
                  control={control}
                  name="mobile"
                  label="Mobile"
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 3 }}>
              Update
            </Button>
          </Box>
        </form>
      </DialogWrapper>
      <DialogWrapper
        open={password}
        setOpen={setPassword}
        title="Update Password"
      >
        <form onSubmit={handleSubmit(onPasswordSubmit)}>
          <Box>
            <Grid container columnSpacing={4} rowSpacing={2}>
              <Grid item xs={12}>
                <FormOutlinedPassword
                  control={control}
                  name="oldPassword"
                  label="Old Password"
                  placeholder="Enter Old Password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormOutlinedPassword
                  control={control}
                  name="newPassword"
                  label="New Password"
                  placeholder="Enter New Password"
                />
              </Grid>
              <Grid item xs={12}>
                <FormOutlinedPassword
                  control={control}
                  name="reEnteredPassword"
                  label="Re-enter New Password"
                  placeholder="Re-enter New Password"
                />
              </Grid>
            </Grid>
            <Button type="submit" variant="contained" sx={{ mt: 3 }}>
              Update
            </Button>
          </Box>
        </form>
      </DialogWrapper>
    </Box>
  );
};

export default UserProfile;

const LabelValue = ({ label, value }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Typography sx={{ fontSize: "13px", opacity: 0.7 }}>{label}</Typography>
      <Typography variant="h5" fontSize="16px">
        {value}
      </Typography>
    </Box>
  );
};
