import { Box, Button, Collapse, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LineText from "../../Components/LineText";
import { useState } from "react";
import { capitalize, formatTime } from "../../../../utils";

export const Description = ({ description }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const plainText = new DOMParser().parseFromString(description, "text/html")
    .body.innerText;

  const lines = plainText.split("\n");

  return (
    <Box sx={{ marginTop: "100px" }}>
      <LineText title={"Description"} />
      <Box sx={{ marginY: "20px" }}>
        <Typography>
          <Collapse in={true}>
            {lines.map((line, index) => (
              <div key={index}>{index < 3 || expanded ? line : null}</div>
            ))}
          </Collapse>
        </Typography>
        {lines.length > 3 && !expanded && (
          <Button
            onClick={toggleExpanded}
            variant="outlined"
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              padding: "10px",
            }}
          >
            Show More
          </Button>
        )}
        {expanded && (
          <Button
            onClick={toggleExpanded}
            variant="outlined"
            size="small"
            sx={{
              marginTop: "10px",
              fontSize: "14px",
              padding: "10px",
            }}
          >
            Show Less
          </Button>
        )}
      </Box>
    </Box>
  );
};

function Courses(props) {
  const coursesArray = props?.courses;
  return (
    <>
      <Description description={props.description}></Description>
      <Box>
        <LineText title={"Courses"} />

        <Box
          sx={{
            marginY: "2rem",
            display: "flex",
            flexWrap: "wrap",
            gap: "20px",
            pb: { xs: 6, sm: 5, md: 3, lg: 0 },
          }}
        >
          {coursesArray?.map((course) => {
            return (
              <CourseCard
                title={course?.title}
                image={course?.imageUrl}
                courseid={course?._id}
                bundleId={props.bundleId}
                duration={course?.duration}
              />
            );
          })}
          {coursesArray?.length === 0 && (
            <Typography sx={{ textAlign: "center" }}>
              No Courses Found
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
}

export default Courses;

export const CourseCard = (props) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: "#F8F8F8",
        width: "500px",
        padding: "10px",
        gap: "20px",
        borderRadius: "15px",
      }}
    >
      {props.image ? (
        <img
          src={props.image}
          alt="Image"
          style={{ width: "150px", height: "100px" }}
        />
      ) : (
        <Box
          sx={{
            backgroundColor: "yellow",
            width: "200px",
            height: "100px",
            borderRadius: "10px",
          }}
        />
      )}

      <Stack gap={"10px"}>
        <Typography sx={{ color: "#2F468C", fontSize: "18px" }} variant="h3">
          {capitalize(props.title)}
        </Typography>
        <Typography variant="body2">{`Duration: ${formatTime(props?.duration)}`}</Typography>
        <Button
          variant="outlined"
          sx={{ padding: 1, width: "max-content" }}
          onClick={() =>
            navigate(`course-curriculum/${props.courseid}`, {
              state: { bundleId: props.bundleId },
            })
          }
        >
          <Typography
            sx={{
              color: "#F4347F",
              fontSize: {
                xs: "12px",
                sm: "15px",
                lg: "15px",
                xl: "15px",
              },
              width: "max-content",
            }}
            color="initial"
          >
            View Curriculum
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
};
