import styled from "@emotion/styled";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, ButtonGroup, Divider, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { getTimeSpent } from "../../../../../api/services";
import { Loader } from "../../../../../components/loader";
const ProgressGraph = () => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [weekStart, setWeekStart] = useState(
    currentDate.clone().startOf("week")
  );
  const [weekEnd, setWeekEnd] = useState(currentDate.clone().endOf("week"));

  const { data, isLoading } = useQuery(
    [
      "timeSpent",
      {
        startDate: weekStart.format("DD-MM-YYYY"),
        endDate: weekEnd.format("DD-MM-YYYY"),
      },
    ],
    getTimeSpent
  );

  const handlePrevWeek = () => {
    const prevWeekStart = weekStart.clone().subtract(1, "week").startOf("week");
    const prevWeekEnd = weekStart.clone().subtract(1, "week").endOf("week");
    setWeekStart(prevWeekStart);
    setWeekEnd(prevWeekEnd);
  };

  const handleNextWeek = () => {
    const nextWeekStart = weekStart.clone().add(1, "week").startOf("week");
    const nextWeekEnd = weekStart.clone().add(1, "week").endOf("week");
    setWeekStart(nextWeekStart);
    setWeekEnd(nextWeekEnd);
  };

  const isNextDisabled = weekStart.isSameOrAfter(moment().startOf("week"));

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "10px",
        boxShadow: "0px 6px 16px #21468329",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ p: 2, fontWeight: 600 }}>
          Time Spent in this week (Min)
        </Typography>
        <StyledButtonGroup sx={{ gap: 1, alignItems: "center", pr: 2 }}>
          <Button
            variant="contained"
            sx={{ px: 0, border: "5px !important" }}
            onClick={handlePrevWeek}
          >
            <KeyboardArrowLeftIcon />
          </Button>
          <Button
            variant="contained"
            sx={{ px: 0 }}
            onClick={handleNextWeek}
            disabled={isNextDisabled}
          >
            <KeyboardArrowRightIcon />
          </Button>
        </StyledButtonGroup>
      </Box>
      <Divider />
      {isLoading ? (
        <Loader />
      ) : (
        <ResponsiveContainer
          width="100%"
          height={400}
          style={{ fontFamily: "figtree_medium" }}
        >
          <BarChart
            data={data?.data?.timeSpent || []}
            margin={{
              top: 30,
              right: 30,
              left: 20,
              bottom: 5,
            }}
            barGap={8}
          >
            <XAxis dataKey="date" axisLine={false} tickLine={false} />
            <YAxis axisLine={false} tickLine={false} />
            <Tooltip />
            <CartesianGrid strokeDasharray="3 3" />
            <Bar
              dataKey="count"
              fill="#16ACAB"
              barSize={25}
              radius={[5, 5, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </Box>
  );
};

export default ProgressGraph;
const StyledButtonGroup = styled(ButtonGroup)(({ theme }) => ({
  "& .MuiButtonGroup-lastButton, & .MuiButtonGroup-middleButton": {
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
    marginLeft: -1,
  },
  "& .MuiButtonGroup-firstButton": {
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
    marginLeft: -1,
  },
}));
