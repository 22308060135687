import { http } from "../http";

export const getDiscussionForum = ({ queryKey }: any) => {
  return http.get("/discussion-forum/student", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getOneDiscussionForum = ({ queryKey }: any) => {
  return http.get(`/discussion-forum/${queryKey[1]}`);
};

export const reply = (queryKey: any) => {
  return http.patch(`/discussion-forum/reply`, queryKey);
};
