import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getCourses,
  getCurriculum,
  postReview,
} from "../../../api/services/learning";
import { icons } from "../../../assets";
import FormOutlinedSelect from "../../../components/FormFields/FormOutlinedSelect";
import FormSelect from "../../SignInPages/Pages/MyTradeTimeTable/components/FormSelect";
import ListOfCurriculums from "../ListOfCurriculums";

const MyLearningSideNav = ({ setToggle }: any) => {
  const navigate = useNavigate();
  const { bundleId, courseId } = useParams();
  const [open, setOpen] = useState<boolean>(false);
  const [stars, setStars] = useState<number | null>(0);
  const queryClient = useQueryClient();

  const { data } = useQuery(["get-curriculum", courseId], getCurriculum);

  const { data: courses } = useQuery(["getCourses", bundleId], getCourses);
  const courseOptions = courses?.data?.courses?.map((item: any) => {
    return {
      value: item?._id,
      label: item?.title,
    };
  });

  const { control, getValues } = useForm({
    defaultValues: {
      course: courseId,
      description: "",
    },
  });

  const { mutate } = useMutation(postReview, {
    onSuccess: (data: any) => {
      queryClient.invalidateQueries("get-curriculum");
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = () => {
    const postData = {
      courseId: courseId,
      rating: stars,
      description: getValues("description"),
    };
    setOpen(false);
    mutate(postData);
  };

  return (
    <Box
      sx={{
        width: "350px",
        display: "flex",
        flexDirection: "column",
        boxShadow: "0px 3px 6px #00000029",
        height: `calc(100vh - ${"70px"})`,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          py: "10px",
          boxShadow: "0px 5px 15px #00000014",
        }}
      >
        <Button
          variant="text"
          disableRipple
          sx={{
            p: 0,
            m: 0,
            fontSize: "13px",
            color: "black",
            opacity: 0.5,
            minWidth: "max-content",
          }}
          startIcon={<KeyboardArrowLeftIcon sx={{ marginRight: "-5px" }} />}
          onClick={() => navigate("/my-learning")}
        >
          Go Back
        </Button>
        <Button
          variant="text"
          disableRipple
          sx={{
            p: 0,
            m: 0,
            fontSize: "13px",
            color: "#f4347f",
            minWidth: "max-content",
          }}
          startIcon={
            <img
              src={icons.ColapseIcon}
              style={{ width: "20px", height: "20px" }}
            />
          }
          onClick={() => setToggle(false)}
        >
          Colapse
        </Button>
        {/* <Button
          variant="text"
          disableRipple
          sx={{
            p: 0,
            m: 0,
            fontSize: "13px",
            color: "#2F468C",
            minWidth: "max-content",
          }}
          startIcon={<StarBorderOutlinedIcon />}
          onClick={() => setOpen(true)}
        >
          Leave a rating
        </Button> */}
      </Box>
      <Typography fontSize={"17px"} fontWeight={600} sx={{ px: 2, pt: 1 }}>
        {`${courses?.data?.trade?.code} - ${courses?.data?.bundle?.name} ${courses?.data?.year?.name}`}
      </Typography>
      <FormOutlinedSelect
        control={control}
        label="Select Course"
        name="course"
        options={courseOptions || []}
        sx={{ py: 1, px: 2 }}
        onChange={(e) => {
          return navigate(`/learnings/bundle/${bundleId}/course/${e}`);
        }}
      />
      <Box
        sx={{
          px: 2,
          py: 1,
          display: "flex",
          flexDirection: "column",
          gap: "5px",
        }}
      >
        <Typography
          color={"#F4347F"}
          fontSize={"12px"}
          fontWeight={600}
          sx={{ textAlign: "right" }}
        >{`${
          Math.round(data?.data?.overview?.progress * 100) / 100
        }% Completed`}</Typography>
        <LinearProgress
          variant="determinate"
          value={data?.data?.overview?.progress}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          backgroundColor: "#2F468C",
          py: 1,
          mt: 1,
          px: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontSize: "18px",
            color: "white",
          }}
        >{`Curriculum `}</Typography>
        <FormSelect
          control={control}
          name="language"
          options={[{ label: "English", value: "ENGLISH" }]}
          sx={{ width: "130px" }}
          color="white"
          defaultValue="ENGLISH"
        />
      </Box>
      <ListOfCurriculums
        data={data?.data?.subjects?.map((item: any) => item.chapters)?.flat()}
      />
      {/* <Dialog
        maxWidth={"xs"}
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: 2,
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={() => setOpen(false)}
            sx={{ alignSelf: "end", height: "20px", width: "20px" }}
          >
            <Close />
          </IconButton>
          <Box
            sx={{
              px: 2,
              textAlign: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "100%",
                mb: 1,
                mx: "auto",
              }}
            >
              <img src={rating} alt="rating" height={"100%"} width={"100%"} />
            </Box>
            <Typography variant="h4">Rate your experience</Typography>
            <Typography variant="body1" sx={{ textAlign: "center" }}>
              Please let us know your feedback of the course. Your review will
              help us improve.
            </Typography>
            <Rating
              name="simple-controlled"
              size="large"
              value={stars}
              sx={{ py: 1 }}
              onChange={(event, newValue) => {
                setStars(newValue);
              }}
            />
            <FormOutlinedInput
              name="description"
              control={control}
              placeholder="write your review…"
              multiline={3}
              sx={{ width: "100%", px: 2 }}
            />
            <Button
              sx={{ width: "150px", my: 2 }}
              variant="contained"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog> */}
    </Box>
  );
};

export default MyLearningSideNav;
