import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getCourseById } from "../../../../api/services";
import { icons } from "../../../../assets";
import { formatTime } from "../../../../utils";
import Card from "../../Components/Card";
import LineText from "../../Components/LineText";

function Curriculum() {
  const params = useParams();
  const courseid = params.courseid;

  const [selectedChapter, setChapter] = useState("");

  const { data } = useQuery(["get-course-by-id", courseid], getCourseById);

  const mergedChapters = data?.data?.subjects?.reduce((acc: any, curr: any) => {
    return acc.concat(curr.chapters);
  }, []);

  return (
    <Box sx={{ marginTop: "100px" }}>
      <LineText title={"Curriculum"} />
      <Box
        sx={{
          width: {
            xs: "80vw",
            sm: "80vw",
            lg: "55vw",
            xl: "55vw",
          },
          marginTop: 2,
        }}
      >
        {mergedChapters?.map((chapter: any) => {
          return (
            <>
              <StyledAcordian
                key={chapter}
                elevation={0}
                disableGutters
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  py: "5px",
                  px: 0,
                  mb: 1,
                  border: "1px solid #22222229",
                  borderRadius: "16px !important",
                }}
                expanded={chapter?.title === selectedChapter}
              >
                <AccordionSummary
                  sx={{
                    p: 0,
                    mx: "15px",
                    borderBottom:
                      chapter?.title === selectedChapter
                        ? "1px solid #cecece76"
                        : 0,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() =>
                    chapter?.title === selectedChapter
                      ? setChapter("")
                      : setChapter(chapter?.title)
                  }
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" fontSize={"17px"}>
                      {chapter?.title}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <AccessTimeIcon sx={{ width: "17px", height: "17px" }} />
                      <Typography variant="body1">
                        {`${formatTime(chapter?.duration)}`}
                      </Typography>
                    </Box>
                  </Box>
                </AccordionSummary>
                <Chapeters data={chapter?.sections} />
              </StyledAcordian>
            </>
          );
        })}
      </Box>
    </Box>
  );
}

export default Curriculum;

export const Chapeters = (data: any) => {
  const [selectedSection, setSection] = useState("");

  return (
    <>
      {data?.data?.map((section: any) => {
        return (
          <>
            <StyledAcordian
              key={section}
              elevation={0}
              disableGutters
              TransitionProps={{ unmountOnExit: true }}
              sx={{ p: 0, mx: "15px" }}
              expanded={section?.title === selectedSection}
            >
              <AccordionSummary
                sx={{
                  borderBottom:
                    section?.title === selectedSection
                      ? "1px solid #cecece54"
                      : 0,
                  p: 0,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() =>
                  section?.title === selectedSection
                    ? setSection("")
                    : setSection(section?.title)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography variant="h5" fontSize={"15px"}>
                    {section?.title}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: 1,
                    }}
                  >
                    <AccessTimeIcon sx={{ width: "17px", height: "17px" }} />
                    <Typography variant="h5" fontSize={"15px"}>
                      {`${formatTime(section?.duration)}`}
                    </Typography>
                  </Box>
                </Box>
              </AccordionSummary>
              <Sections data={section?.topics || []} />
            </StyledAcordian>
          </>
        );
      })}
    </>
  );
};

export const Sections = (data: any) => {
  return data?.data?.map((topic: any, index: any) => {
    return (
      <AccordionDetails
        key={topic}
        sx={{
          m: 0,
          borderBottom:
            data?.data?.length - 1 === index ? 0 : "1px solid #cecece54",
          p: 0,
          py: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pr: 3,
          }}
        >
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <img
              src={
                topic?.eachTopic?.filter((item: any) => item?.type === "VIDEO")
                  ?.length > 0
                  ? icons.play
                  : topic?.eachTopic?.filter(
                      (item: any) => item?.type === "QUIZ"
                    )?.length > 0
                  ? icons.quiz
                  : icons.onlineExam
              }
              alt="icon"
              height={"15px"}
              width={"15px"}
              style={{ opacity: 0.9 }}
            />
            <Typography fontSize={"14px"} sx={{ opacity: 0.9 }}>
              {topic.title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <AccessTimeIcon sx={{ width: "17px", height: "17px" }} />
            <Typography variant="body1" fontSize={"14px"}>
              {formatTime(topic.duration)}
            </Typography>
          </Box>
        </Box>
      </AccordionDetails>
    );
  });
};

export const AboutInstructor = () => {
  const params = useParams();
  const courseid = params.courseid;
  const { data } = useQuery(["get-course-by-id", courseid], getCourseById);
  const instructorData = data?.data?.instructor;
  const imagekey = data?.data?.instructor?.imageKey;
  const instructorimage = `https://digitaliti.s3.ap-south-1.amazonaws.com/${imagekey}`;
  return (
    <>
      <Box sx={{ marginTop: "40px", pb: { xs: 5, sm: 5, md: 3, lg: 0 } }}>
        <LineText title={"About Instructor"} />

        <Box
          sx={{
            paddingY: "20px",
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              xl: "row",
              lg: "row",
            },
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          {imagekey ? (
            <img
              src={instructorimage}
              style={{
                minWidth: "160px",
                height: "160px",
                borderRadius: "10px",
              }}
              alt="Instructor"
            />
          ) : (
            <Box
              sx={{
                minWidth: "160px",
                height: "160px",
                backgroundColor: "yellow",
                borderRadius: "10px",
              }}
            ></Box>
          )}
          <Stack gap={"6px"}>
            <Typography
              sx={{ fontSize: "18px", color: "#0E125E" }}
              variant="h3"
            >
              {instructorData?.name}
            </Typography>
            <Typography variant="caption">Asso. Professor</Typography>
            <Typography>
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
              architecto at nesciunt exercitationem ut vero commodi sapiente
              maiores amet adipisci rem, quo odio, vel labore officiis dolorum
              quia laborum quis.
            </Typography>
          </Stack>
        </Box>
      </Box>
    </>
  );
};

// export const CollapsibleTypography = () => {
//   const [expanded, setExpanded] = useState(false);

//   const toggleExpanded = () => {
//     setExpanded(!expanded);
//   };

//   // Text content
//   const textContent = `
//     Sed ut perspiciatis unde omnis iste natus error sit voluptatem
//     accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
//     quae ab illo inventore veritatis et quasi architecto beatae vitae
//     dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
//     aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
//     eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
//     est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
//     velit, sed quia non numquam eius modi tempora incidunt ut labore
//     et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
//     veniam, quis nostrum exercitationem ullam corporis suscipit
//     laboriosam.
//   `;
//   const lines = textContent.split("\n");
//   return (
//     <div>
//       <Typography>
//         <Collapse in={true}>
//           {lines.map((line, index) => (
//             <div key={index}>{index < 3 || expanded ? line : null}</div>
//           ))}
//         </Collapse>
//       </Typography>
//       {lines.length > 3 && !expanded && (
//         <Button
//           onClick={toggleExpanded}
//           sx={{
//             marginTop: "10px",
//             fontSize: "14px",
//             padding: "10px",
//           }}
//           variant="outlined"
//           size="small"
//         >
//           Show More
//         </Button>
//       )}
//       {expanded && (
//         <Button
//           onClick={toggleExpanded}
//           sx={{
//             marginTop: "10px",
//             fontSize: "14px",
//             padding: "10px",
//           }}
//           variant="outlined"
//           size="small"
//         >
//           Show Less
//         </Button>
//       )}
//     </div>
//   );
// };

export const RelatedBundles = () => {
  return (
    <>
      <Box sx={{ marginTop: "80px" }}>
        <LineText title={"Related Bundles"} />
        <Box
          sx={{
            marginY: "30px",
            display: "flex",
            gap: "20px",
            overflowX: "scroll",
            "&::-webkit-scrollbar": {
              width: "0.5em",
              height: "0.5em",
            },

            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(255, 255, 255, 0.3)",
              borderRadius: "3px",

              "&:hover": {
                background: "rgba(255, 255, 255, 0.4)",
              },
            },
          }}
        >
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
          <Card />
        </Box>
      </Box>
    </>
  );
};

export const StyledAcordian = styled(Accordion)(() => ({
  "&.MuiPaper-root:before": {
    opacity: 0,
  },
}));
