import { Box, Divider, Switch, Typography } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { getExams } from "../../../../../api/services/test";
import Continue from "../../../components/Continue";
import { ExamCard } from "./ExamCard";
import { NoData } from "../../../../../assets";

const TestBundleTab = ({ data }: any) => {
  const [checked, setChecked] = useState<boolean>(false);

  const { data: exams, isLoading } = useQuery(
    [
      "getExams",
      data._id,
      {
        status: checked ? "COMPLETE" : "",
      },
    ],
    getExams
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
      <Box
        sx={{
          width: "100%",
          borderRadius: "10px",
          boxShadow: "0px 6px 16px #21468329",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ p: 2, fontWeight: 600 }}>
            CBT/Online Test
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              pr: 2,
            }}
          >
            <Switch value={checked} onChange={() => setChecked(!checked)} />
            <Typography
              sx={{
                fontSize: "12px",
                alignItems: "center",
                fontWeight: 600,
              }}
            >
              Show only Completed tests
            </Typography>
          </Box>
        </Box>
        <Divider />
        {isLoading ? (
          <Box
            sx={{
              width: "100%",
              height: "40vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className="loader" />
          </Box>
        ) : exams?.data?.exams?.length > 0 ? (
          exams?.data?.exams?.map((item: any, index: number) => (
            <>
              <ExamCard data={item} />
              {index < exams?.data?.exams?.length - 1 && <Divider />}
            </>
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "40vh",
            }}
          >
            <Box sx={{ alignItems: "center", width: "250px" }}>
              <img src={NoData} alt="No Data" height={"100%"} width={"100%"} />
            </Box>
            <Typography variant="body1" sx={{ py: 1,fontWeight:600 }}>
              No Tests Available
            </Typography>
          </Box>
        )}
      </Box>
      <Continue />
    </Box>
  );
};

export default TestBundleTab;
