import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { beginQuiz, getQuizData } from "../../api/services/quiz";
import { getStudentProfile } from "../../api/services/test";
import { logo } from "../../assets";
import { Loader } from "../../components/loader";
import { TitleBox } from "../../components/TitleBox";
import { disableRightClick, handleKeyDown, openFullscreen } from "./instructions";

export const AboutExam = ({ setValue }:any) => {
  const searchParams = new URLSearchParams(window.location.search);
  const { bundleId, courseId } = useParams();
  const { id } = useParams();

  const { data: examData, isLoading: examLoading } = useQuery(
    ["getQuizData", id],
    getQuizData
  );

  const { data: studentData } = useQuery("student-profile", getStudentProfile);

  



  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("contextmenu", disableRightClick);
  }, []);

  return (
    <Box
      sx={{
        height: `100vh`,
        width: "100%",
        py: 5,
        alignContent: "center",
      }}
    >
      {examLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            alignItems: "center",
            boxShadow: "0px 6px 16px #21468329",
            borderRadius: "15px",
            maxWidth: "1440px",
            p: 3,
            gap: 2,
            margin: "auto",
          }}
        >
          <img src={logo} width={220} height={80} alt="logo" />

          <Grid
            container
            spacing={1}
            rowGap={2}
            sx={{ textAlign: "left", width: "70%" }}
          >
            <Grid item xs={12} sm={4}>
              <TitleBox title="Exam Title" value={examData?.data?.name} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Pass Percentage"
                value={`${examData?.data?.passPercentage} %`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TitleBox
                title="Toatl Questions"
                value={`${examData?.data?.addedQuestions}`}
              />
            </Grid>

            {examData?.data?.parts?.map((item: any, index: any) => (
              <Grid item xs={12} sm={4} key={index}>
                <TitleBox title={`Part ${index + 1}`} value={item?.title} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Typography variant="h4" fontSize="14px" sx={{ opacity: "0.6" }}>
                Short Description
              </Typography>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontFamily: "figtree_semibold",
                  "& *": { p: 0, m: 0 },
                }}
                dangerouslySetInnerHTML={{
                  __html: examData?.data?.description,
                }}
              ></Typography>
            </Grid>
          </Grid>

          <Box sx={{ width: "100%", display: "absolute" }}>
            <Divider />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                mt: 2,
              }}
            >
              <Button
                onClick={() => {
                  openFullscreen();
                  setValue("instructions");
                }}
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
