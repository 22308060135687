import { ChevronRight } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalize } from "../../../utils";

export const NavigationContainer = ({ buttonLabel, color, children }) => {
  const [open, setOpen] = useState(false);

  let ref = useRef<HTMLButtonElement>();

  useEffect(() => {
    const handler = (event) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [open]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setOpen(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setOpen(false);
  };
  return (
    <Box
      sx={{
        position: "relative",
        alignItems: "center",
      }}
    >
      <Button
        sx={{
          color: color,
          width: "max-content",
          p: 0,
        }}
        size="small"
        ref={ref}
        onClick={() => setOpen(!open)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {buttonLabel}
        <Box
          sx={{
            pt: "13px",
            position: "absolute",
            top: "100%",
            zIndex: 999,
          }}
        >
          {open && (
            <Box
              sx={{
                background: "white",
                borderRadius: "3px",
              }}
            >
              {children}
            </Box>
          )}
        </Box>
      </Button>
    </Box>
  );
};

export const MenuItems = ({ menuItems, categoryUid = "" }) => {
  return (
    <Box position="relative">
      <Box
        sx={{
          background: "white",
          minWidth: "280px",
          minHeight: "300px",
          boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.3)",
          border: "1px solid rgba(0, 0, 0, 0.2)",
        }}
      >
        {menuItems?.length > 0 ? (
          <Box top="-10px">
            {menuItems?.map((item, index) => (
              <CategoryListItem item={item} key={index} />
            ))}
          </Box>
        ) : (
          <Box top="-10px">
            <Typography sx={{ alignSelf: "center", pt: 5 }}>No Data</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const CategoryListItem = ({ item }) => {
  const [open, setOpen] = useState(false);
  let ref = useRef<any>();

  useEffect(() => {
    const handler = (event) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [open]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setOpen(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setOpen(false);
  };
  return (
    <ListItemButton
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        p: 0,
        width: "100%",
        position: "unset",
        whiteSpace: "nowrap",
        color: "black",
        "&:hover": {
          background: "#f4347f",
          color: "white",
          "& .MuiListItemIcon-root": {
            color: "white",
          },
        },
      }}
    >
      <Box
        width={"100%"}
        sx={{
          p: 1,
          display: "flex",
          justifyContent: "space-between",
          px: 2,
          alignItems: "center",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Typography sx={{ fontSize: "15px" }}>{item?.name}</Typography>
        {item?.courseBundles?.length ? (
          <ListItemIcon
            sx={{
              minWidth: 0,
              color: "black",
              justifySelf: "flex-end",
            }}
          >
            <ChevronRight fontSize="small" />
          </ListItemIcon>
        ) : null}
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 999,
          left: "calc(100% - 2px)",
          top: "0px",
        }}
      >
        {open && (
          <>
            {item?.courseBundles?.length ? (
              <Box
                sx={{
                  borderRadius: "3px",
                }}
              >
                <Box position="relative">
                  <Box
                    sx={{
                      background: "white",
                      minWidth: "280px",
                      minHeight: "300px",
                      boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.3)",
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {item?.courseBundles?.length > 0 ? (
                      <Box top="-10px">
                        {item?.courseBundles?.map((item, index) => (
                          <CourseBundleItem item={item} key={index} />
                        ))}
                      </Box>
                    ) : (
                      <Box top="-10px">
                        <Typography sx={{ alignSelf: "center", pt: 5 }}>
                          No Data
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </ListItemButton>
  );
};
const CourseBundleItem = ({ item }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let ref = useRef<any>();
  useEffect(() => {
    const handler = (event) => {
      if (open && ref.current && !ref.current.contains(event.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [open]);

  const onMouseEnter = () => {
    window.innerWidth > 960 && setOpen(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > 960 && setOpen(false);
  };
  return (
    <ListItemButton
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{
        p: 0,
        width: "100%",
        position: "unset",
        whiteSpace: "nowrap",
        color: "black",
        "& .MuiListItemIcon-root": {
          color: "black",
        },
        "&:hover": {
          background: "#f4347f",
          color: "white",
          "& .MuiListItemIcon-root": {
            color: "white",
          },
        },
      }}
    >
      <Box
        display="flex"
        width={"100%"}
        sx={{
          p: 1,
          px: 2,
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/bundles/${item?._id}`);
        }}
      >
        <Typography fontSize={"15px"}>
          {capitalize(item?.bundle?.name)}
        </Typography>
        {item?.courses?.length ? <ChevronRight fontSize="small" /> : <></>}
      </Box>
      <Box
        sx={{
          position: "absolute",
          zIndex: 999,
          left: "calc(100% - 2px)",
          top: "0px",
        }}
      >
        {open && (
          <>
            {item?.courses?.length ? (
              <Box
                sx={{
                  borderRadius: "3px",
                }}
              >
                <Box position="relative">
                  <Box
                    sx={{
                      background: "white",
                      minWidth: "280px",
                      minHeight: "300px",
                      boxShadow: "10px 10px 20px -10px rgba(0,0,0,0.3)",
                      border: "1px solid rgba(0, 0, 0, 0.2)",
                    }}
                  >
                    {item?.courses?.length > 0 ? (
                      <Box top="-10px">
                        {item?.courses?.map((course, index) => (
                          <ListItemButton
                            sx={{
                              p: 0,
                              width: "100%",
                              position: "unset",
                              whiteSpace: "nowrap",
                              color: "black",
                              "&:hover": {
                                background: "#f4347f",
                                color: "white !important",
                                "& .MuiListItemIcon-root": {
                                  color: "white",
                                },
                              },
                            }}
                            onClick={() => {
                              return navigate(
                                `bundles/${item?.bundle?._id}/course-curriculum/${course?._id}`,
                                {
                                  state: { bundleId: item?._id },
                                }
                              );
                            }}
                          >
                            <Typography fontSize={"15px"} sx={{ p: 1, px: 2 }}>
                              {capitalize(course?.title)}
                            </Typography>
                          </ListItemButton>
                        ))}
                      </Box>
                    ) : (
                      <Box top="-10px">
                        <Typography sx={{ alignSelf: "center", pt: 5 }}>
                          No Data
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </>
        )}
      </Box>
    </ListItemButton>
  );
};
