import { Box, Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { getEBooks } from "../../../../../api/services/ebooks";
import { NoData } from "../../../../../assets";
import { Loader } from "../../../../../components/loader";
import Continue from "../../../components/Continue";
import { EBookCard } from "./EBookCard";

const BundleTab = ({ data }: any) => {
  const { data: books, isLoading } = useQuery(
    ["getEBooks", data._id],
    getEBooks
  );
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          sx={{
            boxShadow: "0px 6px 16px #21468329",
            borderRadius: "10px",
          }}
        >
          <Typography sx={{ p: 2, fontWeight: 600 }}>My E-Books</Typography>
          <Divider sx={{ opacity: "60%" }} />
          <Box sx={{ display: "flex", flexDirection: "column", py: 1, gap: 1 }}>
            {books?.data?.ebooks?.length > 0 ? (
              books?.data?.ebooks?.map((item: any) => <EBookCard data={item} />)
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "40vh",
                }}
              >
                <Box sx={{ alignItems: "center", width: "250px" }}>
                  <img
                    src={NoData}
                    alt="No Data"
                    height={"100%"}
                    width={"100%"}
                  />
                </Box>
                <Typography variant="body1" sx={{ py: 1, fontWeight: 600 }}>
                  No E-Books Available
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      )}

      <Continue />
    </Box>
  );
};

export default BundleTab;
