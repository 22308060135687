import { Avatar } from "@mui/material";

export const Answered = ({ label, onClick = () => {}, sx, selected }: any) => {
  return (
    <Avatar
      sx={{
        bgcolor: selected ? "#4C8D1B" : "#5FAE23",
        width: 34,
        height: 34,
        fontSize: 16,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        boxShadow: selected ? "0 4px 8px 0 #00000033" : "none",
        ...sx,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const NotAnswered = ({
  label,
  onClick = () => {},
  sx,
  selected,
}: any) => {
  return (
    <Avatar
      sx={{
        bgcolor: selected ? "#A23735" : "#A94442",
        width: 34,
        height: 34,
        fontSize: 16,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        boxShadow: selected ? "0 4px 8px 0 #00000033" : "none",
        ...sx,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const Review = ({ label, onClick = () => {}, sx, selected }: any) => {
  return (
    <Avatar
      sx={{
        bgcolor: selected ? "#067FC6" : "#0093E9",
        width: 34,
        height: 34,
        fontSize: 16,
        boxShadow: selected ? "0 4px 8px 0 #00000033" : "none",
        ...sx,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const NotVisited = ({
  label,
  onClick = () => {},
  sx,
  selected,
}: any) => {
  return (
    <Avatar
      sx={{
        bgcolor: selected ? "#0F7B7A" : "#80d0c7",
        width: 34,
        height: 34,
        fontSize: 16,
        borderRadius: "5px",
        boxShadow: selected ? "0 4px 8px 0 #00000033" : "none",
        ...sx,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};

export const AnsweredAndReview = ({
  label,
  onClick = () => {},
  sx,
  selected,
}: any) => {
  return (
    <Avatar
      sx={{
        bgcolor: selected ? "#067FC6" : "#0093E9",
        width: 34,
        height: 34,
        fontSize: 16,
        boxShadow: selected ? "0 4px 8px 0 #00000033" : "none",
        ...sx,
      }}
      alt=""
      onClick={onClick}
    >
      {label}
    </Avatar>
  );
};
